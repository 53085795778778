import { gql } from 'apollo-boost'

export const ALL_ACQUISITIONS = gql`
  query AllAquisitions ($section: ID!) {
    allAcquisitions(filter:{
      section: $section
    },
    order: PLAN_START_DATE__DESC) {
      edges {
        node {
          id
          type
          name
          description
          planStartDate
          planEndDate
          startDate
          endDate
          tasks {
            edges {
              node {
                isCompleted
              }
            }
          }
          associatedAccountEvents {
            value
          }
        }
      }
    }
    section(id: $section) {
      name
      id
    }
  }
`

export const SHOW_GANTT_MODAL = gql`
  mutation ShowGanttModal ($data: [Node]!) {
    showGanttModal(data: $data) @client
  }
`

export const SHOW_ACQUISITION_FORM_MODAL = gql`
  mutation ShowAcquisitionFormModal($sectionId: ID!, $acquisitionId: ID) {
    showAcquisitionFormModal(sectionId: $sectionId, acquisitionId: $acquisitionId) @client
  }
`

export const DELETE_ACQUISITION = gql`
  mutation DeleteAcquisition($id: ID!) {
    deleteAcquisition(id: $id) {
      id
    }
  }
`

export const ACQUISITION_DELETED = gql`
  subscription AcquisitionDeleted {
    acquisitionDeleted
  }
`

export const ACQUISITION_ADDED = gql`
  subscription AcquisitionAdded {
    acquisitionAdded {
      id
      type
      name
      description
      planStartDate
      planEndDate
      startDate
      endDate
      tasks {
        edges {
          node {
            isCompleted
          }
        }
      }
      associatedAccountEvents {
        value
      }
    }
  }
`
