import React, { useState } from 'react'
import { AiOutlineVerticalAlignTop } from 'react-icons/ai'

import { useWindowScrollInfo } from '../../hooks'

const toTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

const ToTopBtn = () => {
  const [showToTopBtn, setShowToTopBtn] = useState(false)

  useWindowScrollInfo({
    effect: ({ currPos }) => {
      const isShow = Math.abs(currPos.y) > 300
      if (isShow !== showToTopBtn) setShowToTopBtn(isShow)
    },
    wait: 300
  })

  return (
    <button
      className="to-top-button"
      style={{ display: showToTopBtn ? 'block' : 'none' }}
      onClick={toTop}>
      <AiOutlineVerticalAlignTop />
    </button>
  )
}

export default ToTopBtn