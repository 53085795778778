import gql from 'graphql-tag'

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
      refreshToken
    }
  }
`

export const SET_TOKENS = gql`
  mutation SetTokens($accessToken: String!, $refreshToken: String!) {
    setTokens(accessToken: $accessToken, refreshToken: $refreshToken) @client
  }
`

export const LOGIN_WITH_GOOLGE = gql`
  mutation loginWithGoogle($token: String) {
    loginWithGoogle(token: $token) {
      accessToken
      refreshToken
    }
  }
`