import { gql } from 'apollo-boost'

const typeDefs = gql`
  type GanttTask {
    id: String
    name: String
    start: String
    end: String
    progress: Int
    dependencies: String
  }

  type GanttModal {
    isHidden: Boolean!
    tasks: [GanttTask]
  }

  enum AlertClientEnum {
    WARNING_ALERT
    ERROR_ALERT
    SUCCESS_ALERT
  }

  type AlertClient {
    id: ID!
    type: AlertClientEnum!
    message: String!
    time: Int
  }

  extend type Query {
    isLoggedIn: Boolean!
    alerts: [AlertClient]!
  }

  type GroupByElementExtention {
    labels: [String]!
    values: [Number]!
  }

  extend type AccountConnection {
    groupByElement: GroupByElementExtention
  }
  

  type TimeChartData {
    x: String!
    y: Float!
  }

  type BarChartData {
    labels: [String]!
    values: [Float]!
  }

  extend type AccountEventConnection {
    timeChartData: [TimeChartData]!
    typeChartData: BarChartData!
    distinctTypes: [String]!
  }
`

export default typeDefs