import { gql } from 'apollo-boost'

export const ADD_ALERT = gql`
  mutation AddAlert ($type: AlertClientEnum, $message: String){
    addAlert(type: $type, message: $message) @client
  }
`

export const DELETE_ALERT = gql`
  mutation DeleteAlert ($id: ID!) {
    deleteAlert(id: $id) @client
  }
`

export const GET_ALERTS = gql`
  query GetAlerts {
    alerts @client {
      type
      message
      time
      id
    }
  }
`