import React, { Fragment, useMemo } from 'react'
import { useParams, Link } from 'react-router-dom'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import _ from 'lodash'
import Chart from '../../components/Chart'

import { useWindowSize } from '../../hooks'
import { topChart } from './chartConfigs'
import MainContaintLoading from '../../components/MainContaintLoading'

import * as dic from '../../helpers/accountDictionary'
import { currencyFormat } from '../../helpers'


const AuxiliariesAccountList = () => {
  const { unit, accountNumber } = useParams()
  const allAccountsQuery = useQuery(ALL_AUXILIARY_ACCOUNTS, {
    variables: { unitCode: unit, number: accountNumber }
  })

  return (
    <>
      <header className="header">Lista de contas { '>' + unit + '>' + accountNumber }</header>
      <main className="main">
        <ComponentRenderer 
          { ...allAccountsQuery }
        />
      </main>
      <footer className="footer"></footer>
    </>
  )
}

const ComponentRenderer = ({ loading, error, data }) => {
  const { width } = useWindowSize()
  const chartOptions = useMemo(() => {
    if (!data) return {}
    const { labels, values } = data.allAccounts.groupByElement
    return topChart({ labels, values, truncateXLen: (width > 750) ? 30 : 8 })
  }, [data, width])

  if (loading) return <MainContaintLoading />
  if (error) return <div>Erro ao tentar recuperar informações das contas!</div>
  if (data) {
    const { aggregated } = data.allAccounts.groupByElement
    return (
      <>
        <div className="auto-fit-grid">
          <Chart 
            options={ chartOptions } 
            style={{ maxHeight: "300px" }}
            className="simple-card" />
        </div>
        
        {aggregated && _.map(aggregated, ({nodes}, elementCode) => (
          <Fragment key={elementCode}>
            
            <div className="grid-header-divider" id={_.camelCase(dic.element(elementCode))}>
              { dic.element(elementCode) } ({elementCode})
            </div>
            
            <div className="auto-fill-grid auto-fill-grid--large">
              { nodes.map(node => (<AuxiliaryListCard { ... node } key={node.id} /> ))}
            </div>
          
          </Fragment>
        )) }
      </>
    )
  }
}

const secondaryClass = val => {
  const src = val.slice(-4)
  const secondary = ['1080', '2410']
  return secondary.includes(src)? 'auxiliary-list-card--secondary' : ''
}
const AuxiliaryListCard = ({ detailing, balance, id, description }) => (
  <Link className={`auxiliary-list-card tooltip ${secondaryClass(detailing)}`} to={`/account-events/${id}`}>
    { description && <span className="tooltip__text tooltip__text--top-left">{ description }</span> }
    <div className="auxiliary-list-card__info-left">
      <div>{ detailing.slice(13,17) }</div>
      <div>{ _.truncate(dic.action(detailing),{length: 60}) }</div>
    </div>
    <div className="auxiliary-list-card__info-right">
      <div>{ detailing.slice(-4).replace(/(\d{2})(\d{2})/, '$1 . $2') }</div>
      <div>{ dic.source(detailing) }</div>
    </div>
    <div className="auxiliary-list-card__value">{ currencyFormat(balance) }</div>
    <div className="auxiliary-list-card__footer">{ dic.format(detailing) }</div>
  </Link>
)

export default AuxiliariesAccountList

const ALL_AUXILIARY_ACCOUNTS = gql`
  query allAuxiliaryAccounts ($unitCode: String!, $number: String!) {
    allAccounts (filter: {
      unitCode: $unitCode
      number: $number
    }) {
      edges {
        node {
          detailing
          balance
          description
          id
        }
      }
      groupByElement @client {
        labels
        values
        aggregated
      }
    }
  }
`