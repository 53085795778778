import React from 'react'
import {useMutation} from '@apollo/react-hooks'
import moment from 'moment'
import { FiEdit } from 'react-icons/fi'
import { AiFillDiff } from 'react-icons/ai'
import _ from 'lodash'

import { currencyFormat } from '../../../helpers'
import { SHOW_ACCOUNT_EVENT_MODAL, SHOW_ACCOUNT_EVENT_DESCRIPTION_MODAL } from '../gqls'

const EventsList = ({ data, filterByType, showDescriptions }) => {
  const [ showModal ] = useMutation(SHOW_ACCOUNT_EVENT_MODAL)
  const [ showDescriptionModal ] = useMutation(SHOW_ACCOUNT_EVENT_DESCRIPTION_MODAL)

  const checkFilter = node => !filterByType || (node.type === filterByType) ? 'show' : ''
  const showModalHandler = eventId => showModal({ variables: { eventId } })
  const showDescriptionModalHandler = eventId => showDescriptionModal({ variables: { eventId } })

  return (
    <ul className="unstyled-list">
      {data.map(({ node }, idx) => (
        <li key={idx} className={`account-events-documents__item ${checkFilter(node)}`}>
          <div className="account-events-documents__actions">
            <button className="btn-icon" onClick={() => showDescriptionModalHandler(node.id)}><FiEdit /></button>
            <button className="btn-icon" onClick={() => showModalHandler(node.id)}><AiFillDiff /></button>
          </div>
          <div className="account-events-documents__type">{_.capitalize(node.type)}</div>
          <div className="account-events-documents__id">Doc. {node.unitOrigin} - {node.documentNumber}</div>
          <div className="account-events-documents__date">{moment(node.registryDate).format('DD [/] MM [/] YYYY, h:mm:ss a')}</div>
          <div className="account-events-documents__value">{currencyFormat(node.value)}</div>
          <div className="account-events-documents__balance">{currencyFormat(node.balance)}</div>
          {node.description && <div className={`account-events-documents__description ${showDescriptions ? 'show' : ''}`}><pre>{node.description}</pre></div>}
        </li>
      ))}
    </ul>
  )
}

export default EventsList