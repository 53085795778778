import React, { useState } from 'react'
import { GiSave, GiCancel } from 'react-icons/gi'

const EditableNote = ({ value, onSave }) => {
  const [note, setNote] = useState(value)
  const saveHandler = () => {
    if (value !== note) onSave(note)
  }

  return (
    <div className="editable-note">
      <textarea placeholder="Anote aqui..." name="note" value={note} onChange={e => setNote(e.target.value)} className="editable-note__textarea" />
      {(note !== value) && (
        <div className="editable-note__actions">
          <button onClick={saveHandler} className="editable-note__save-btn"><GiSave /></button>
          <button onClick={() => setNote(value)} className="editable-note__cancel-btn"><GiCancel /></button>
        </div>
      )}
    </div>
  )
}

export default EditableNote