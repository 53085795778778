import React from 'react'
import { useGoogleLogout } from 'react-google-login'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { AiOutlineLogout, AiOutlineProfile } from 'react-icons/ai'
import { LOGOUT, LOGGED_IN_USER } from '../gqls'
import MainContaintLoading from '../../MainContaintLoading'

const onError = error => console.log(error)

const Profile = () => {
  const { data, loading } = useQuery(LOGGED_IN_USER, { onError })
  const [logout] = useMutation(LOGOUT)
  const history = useHistory()
  const { signOut, loaded } = useGoogleLogout({
    onLogoutSuccess: () => console.log('Conta google logout!'),
    onFailure: () => console.log('Erro ao tentar sair da conta google!')
  })

  const logoutHandler = async () => {
    if (loaded) signOut()
    logout()
    history.replace('/login')
    history.go()
  }
  const profileHandler = () => history.push('/profile')

  if (loading) return <MainContaintLoading size="tiny" />

  return (
    <>
      {data &&  
        <div className="sidenav__profile">
          {data.loggedInUser.picture && <img className="sidenav__profile-picture" src={data.loggedInUser.picture} alt={data.loggedInUser.nam} />}
          <p className="sidenav__profile-name">{data.loggedInUser.name ? data.loggedInUser.name : data.loggedInUser.email}</p>
          <div className="sidenav__profile-actions">
            <button className="btn-icon" onClick={profileHandler}><AiOutlineProfile /></button>
            <button className="btn-icon" onClick={logoutHandler}><AiOutlineLogout /></button>
          </div>
        </div>
      }
    </>
  )
}

export default Profile