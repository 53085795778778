import React from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { useQuery, useMutation } from '@apollo/react-hooks'
import moment from 'moment'

import GridLayer from './GridLayer'
import DateLayer from './DateLayer'
import BarLayer from './BarLayer'
import ArrowLayer from './ArrowLayer'
import MainContaintLoading from '../../components/MainContaintLoading'

import { GANTT_MODAL, HIDE_GANTT_MODAL } from './gqls'

import { options, getMinDate, getMaxDate } from './helpers'
const GanttModal = () => {
  const { data, loading } = useQuery(GANTT_MODAL)

  if (loading) return <MainContaintLoading />
  const { tasks, isHidden } = data.ganttModal

  return (
    <div className={`gantt-chart-wrapper ${isHidden? '':'show'}`}>
      { tasks && <Gantt tasks={tasks} /> }
      { (!tasks || !tasks.length) && <div>Nenhuma tarefa para renderizar!</div>}

      <CloseButton />      
    </div>
  )
}

const CloseButton = () => {
  const [hideGanttModal] = useMutation(HIDE_GANTT_MODAL)
  return (
    <button className="btn-icon gantt-chart-wrapper__close-btn" onClick={() => hideGanttModal()}><AiOutlineClose /></button>
  )
}

const Gantt = ({ tasks }) => {
  const { columnWidth, headerHeight, barHeight, padding } = options

  const minDate = getMinDate(tasks)
  const maxDate = getMaxDate(tasks)

  const countDates = moment(maxDate).diff(minDate, 'day')
  const countTasks = tasks.length

  const gridWidth = countDates * columnWidth
  const gridHeight = headerHeight + padding + (barHeight + padding) * countTasks

  return (
    <div className="gantt-container">
      
      <svg height={gridHeight + 50} width={gridWidth} xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" className="gantt">
        <GridLayer width={gridWidth} countTasks={countTasks} minDate={minDate} />
        <DateLayer countDates={countDates} minDate={minDate} />
        <ArrowLayer tasks={tasks} minDate={minDate} />
        <BarLayer tasks={tasks} minDate={minDate} />

        <g> {/* progress layer */}
        </g>

        <g> {/* datails layer */}
        </g>
      </svg>
    </div>
  )
}

export default React.memo(GanttModal)