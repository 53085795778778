import React from 'react'
import { set } from 'lodash'
import { concat } from 'lodash'

import AccountComparation from './AccountComparation'
import AccountEventsUpdateList from './AccountEventsUpdateList'
import { useXlsToJson } from '../../hooks'

const AccountsUpdate = () => {

  const [ fetchAccounts, accountsResponse ] = useXlsToJson(accountsJsonHandler)
  const [ fetchEvents, eventsResponse ] = useXlsToJson(eventsJsonHandler)
  
  const accFileChangeHandler = e => fetchAccounts(e.target.files[0])
  const eveFileChangeHandler = e => fetchEvents(e.target.files[0])

  return (
    <>
      <header className="header">Atualizar contas</header>  
      <footer className="footer"></footer>
      <main className="main">
        <div className="auto-fit-grid">
          <div className="simple-card">
            <div className="simple-card__featured">Saldo de contas para referência</div>
            <input type="file" name="accountsFile" onChange={ accFileChangeHandler } className="input-file" />
            <AccountsResponseRenderer 
              { ... accountsResponse } />
          </div>
          <div className="simple-card simple-card--secondary">
            <div className="simple-card__featured">Eventos de extrato para atualização</div>
            <input type="file" name="eventsFile" onChange={ eveFileChangeHandler } className="input-file" />
            <EventsResponseRenderer 
              { ... eventsResponse } />
          </div>
        </div>
      </main>
    </>
  )
}

const accountsJsonHandler = json => {
  const sheet = json.data[0]
  const unit = sheet.data[3].slice(1).join('').slice(0,7)
  const account = sheet.data[4].slice(1).join('').split(' ')[0].replace(/\D/g, '')
  const balances = sheet.data.slice(8).reduce((acc, [key, value]) => set(acc, key, value), {})

  return { unit, account, balances }
}

const AccountsResponseRenderer = ({ error, data, loading }) => {
  if (error) return <div>Erro ao carregar arquivo!</div>
  if (loading) return <div>Carregando...</div>
  if (data) return (
    <AccountComparation 
      unit={data.unit} 
      account={data.account} 
      balances={data.balances} /> 
  )

  return <div></div>
}

const eventsJsonHandler = (json) => {
  const sheet = json.data[0]
  const year = sheet.data[1][3]
  const unit = sheet.data[3][3].slice(0, 7)
  const account = sheet.data[4][3].replace(/\D/g, '').slice(0, 9)
  const auxiliaryAccount = sheet.data[7][3]
  const events = sheet.data.slice(13).reduce((acc, cur) => { 
    const [, unitOrigin,, registryDate, documentNumber, type, value] = cur
    return concat(acc, [ { unitOrigin, registryDate, documentNumber, type, value } ])
  },[])

  return { year, unit, account, auxiliaryAccount, events }
}

const EventsResponseRenderer = ({ error, data, loading }) => {
  if (error) return <div>Erro ao carregar arquivo!</div>
  if (loading) return <div>Carregando...</div>
  if (data) return (
    <AccountEventsUpdateList 
      year={data.year}
      unit={data.unit}
      account={data.account}
      auxiliaryAccount={data.auxiliaryAccount}
      events={data.events} />
  )

  return <div></div>
}

export default AccountsUpdate