import { fetch } from 'whatwg-fetch'
import _ from 'lodash'
import crypto from 'crypto'
import config from '../config'

const TOKEN_KEY = "tokens"

const saveTokens = (tokens) => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(tokens))
}

const getTokens = () => {
  const tokens = JSON.parse(localStorage.getItem(TOKEN_KEY))

  return tokens ? tokens : {
    accessToken: "",
    refreshToken: ""
  }
}

const genID = data => {
  const current_date = (new Date()).valueOf().toString();
  const jsonData = JSON.stringify(data)
  return crypto.createHash('sha1').update(current_date + jsonData).digest('hex')
}

const deleteTokens = () => {
  localStorage.removeItem(TOKEN_KEY)
}

const colors = [
  "#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9",
  "#c45850", '#FF0000', '#800000', '#FFFF00',
  '#808000', '#00FF00', '#008000', '#00FFFF',
  '#008080', '#0000FF', '#000080', '#FF00FF',
  '#800080',
]

const fetchXlsToJson = async data => {
  return fetch(config.xls2json.URL, {
    method: 'POST',
    body: data,
  }).then(response => {
    return response.json()
  })
}

const currencyFormat = val => {
  if (typeof val !== 'number') return val
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(val)
}

const copyToClipboard = str => {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0): false
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
  if (selected) {
    document.getSelection().removeAllRanges()
    document.getSelection().addRange(selected)
  }
}

const ganttDataTransform = ({ node }) => {
  const dependenciesArr = _.get(node, ['dependencies','edges'], [])
  const dependencies = dependenciesArr.reduce((acc, {node}) => (acc === '') ? node.id : [acc, node.id].join(', '), '')
  let progress = node.isCompleted ? 100 : 0
  const tasks = _.get(node, ['tasks', 'edges'], [])
  if (tasks.length) {
    const completedTasks = tasks.reduce((acc, {node}) => node.isCompleted? acc+1: acc,0)
    progress = (completedTasks/tasks.length)*100
  }

  return {
    id: node.id,
    name: node.name,
    start: node.planStartDate,
    end: node.planEndDate,
    progress,
    dependencies,
    __typename: 'GanttTask'
  }
}

export {
  ganttDataTransform,
  fetchXlsToJson,
  currencyFormat,
  colors,
  copyToClipboard,
  saveTokens,
  getTokens,
  deleteTokens,
  genID
}