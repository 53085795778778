import { gql } from 'apollo-boost'

export const ACCOUNT_EVENT_MODAL = gql`
  query accountEventModal {
    accountEventModal @client {
      isHidden
      eventId
    }
  }
`

export const HIDE_ACCOUNT_EVENT_MODAL = gql`
  mutation HideAccountEventModal {
    hideAccountEventModal @client
  }
`

export const ALL_SECTIONS = gql`
  query AllSections {
    allSections {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const ALL_AQUISITIONS = gql`
  query AllAcquisitions ($section: ID!) {
    allAcquisitions (filter: {
      section: $section
    }) {
      edges {
        node {
          name
          id
        }
      }
    }
  }
`

export const ASSOCIATE_ACCOUNT_EVENT = gql`
  mutation AssociateAccountEvent (
    $accountEventId: ID!, 
    $acquisitionId: ID!, 
    $value: Float!, 
    $description: String
  ) {
    associateAccountEvent(input: {
      accountEventId: $accountEventId
      acquisitionId: $acquisitionId
      value: $value
      description: $description
    }) {
      id
      balance
      value
      description
      associations {
        id
        value
        description
        acquisition {
          id
          name
          tasks (
            first: 1
            filter: {
              isCompleted: false
            }
            order: PLAN_START_DATE__ASC
          ) {
            edges {
              node {
                name
              }
            }
          }
        }
      }
    }
  }
`

export const ACCOUNT_EVENT = gql`
  query AccountEvent ($id: ID!) {
    accountEvent(id: $id) {
      id
      balance
      value
      description
      associations {
        id
        value
        description
        acquisition {
          id
          name
        }
      }
    }
  }
`

export const DISASSOCIATE_ACCOUNT_EVENT = gql`
  mutation DisassociateAccountEvent (
    $acquisitionId: ID!, 
    $associationId: ID!
  ) {
    disassociateAccountEvent(input: {
      acquisitionId: $acquisitionId
      associationId: $associationId
    }) {
      id
      balance
      value
      description
      associations {
        id
        value
        description
        acquisition {
          id
          name
        }
      }
    }
  }
`