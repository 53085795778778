import React, { useEffect, useMemo } from 'react'
import { upperFirst, toLower, set, get } from 'lodash'
import { GiSave } from 'react-icons/gi'
import { GoCheck } from 'react-icons/go'
import { MdRefresh } from 'react-icons/md'
import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'

import { element as elementDic, format } from '../../../helpers/accountDictionary'
import { currencyFormat } from '../../../helpers'

const FIND_OR_INSERT_ACCOUNT = gql`
  mutation findOrInsertAccount (
    $detailing: String!
    $unitCode: String!
    $number: String!
  ){
    findOrInsertAccount(input: {
      detailing: $detailing
      unitCode: $unitCode
      number: $number
    }) {
      id
      number
      events {
        edges {
          node {
            id
            documentNumber
            unitOrigin
            registryDate
          }
        }
      }
    }
  }
`

// refactore urso urso
const AccountEventsUpdateList = ({ events, account, unit, auxiliaryAccount }) => {
  
  const [ findOrInsertAccount, { loading, error, data } ] = useMutation(FIND_OR_INSERT_ACCOUNT)
  
  useEffect(() => {
    findOrInsertAccount({ variables: { detailing: auxiliaryAccount, number: account, unitCode: unit } })

  },[ auxiliaryAccount, account, unit, findOrInsertAccount ])
  
  
  const savedEvents = useMemo(() => {
    if (!data) return {}
    return data
      .findOrInsertAccount
      .events
      .edges.reduce((acc, { node }) => set(acc, [node.unitOrigin, node.documentNumber, node.registryDate], true), {})

  }, [data])
  
  const hasBeenSeved = event => get(savedEvents, [ event.unitOrigin, event.documentNumber, parseDate(event.registryDate) ])

  if (error) return <div>Erro ao tenter recuperar informações salvas dos eventos</div>
  if (loading) return <div>Carregando...</div>
  
  return (
    <>
      <div className="events__definition">Unidade executora: {unit}</div>
      <div className="events__definition">Conta contábil: {account}</div>
      <div className="events__definition">{upperFirst(toLower(elementDic(auxiliaryAccount)))}</div>
      <div className="events__definition">{format(auxiliaryAccount)}</div>
      <ul className="events-list">
        { data && events.map((event, idx) => (
          <li key={idx} className="events-list__item">
            <div className="events-list__date">{ event.registryDate }</div>
            <div className="events-list__doc">Doc.{ event.documentNumber }</div>
            <div className="events-list__type">{ upperFirst(toLower(event.type)) }</div>
            <div className="events-list__value">{ currencyFormat(event.value) }</div>
            <div className="events-list__actions">
              { !hasBeenSeved(event) && <SaveAccountEventBtn event={event} accountId={data.findOrInsertAccount.id} /> }
              { hasBeenSeved(event) && <div className="events-list__check"><GoCheck /></div> }
            </div>
          </li>
        ))}
      </ul>
    </>
    
  )
}

const SAVE_ACCOUNT_EVENT = gql`
  mutation SaveAccountEvent(
    $value: Float!, 
    $registryDate: Date!, 
    $accountId: ID!, 
    $documentNumber: Int!, 
    $unitOrigin: String!
    $type: String!
  ){
    saveAccountEvent(input: {
      value:  $value
      registryDate: $registryDate
      accountId:  $accountId
      documentNumber: $documentNumber
      unitOrigin: $unitOrigin
      type: $type
    }) {
      id
      value
      registryDate
      type
    }
}
`

const parseDate = (date) => {
  const [day, month, year, hour, minute, second, milli] = date.split(/\D/)
  return new Date(year, (month - 1), day, hour, minute, second, milli).toISOString()
}

const SaveAccountEventBtn = ({ event, accountId }) => {
  const [saveAccountEvent, trackingEvent] = useMutation(SAVE_ACCOUNT_EVENT)
  
  const onClickHandler = () => saveAccountEvent({
    variables: {
      ...event,
      registryDate: parseDate(event.registryDate),
      documentNumber: parseInt(event.documentNumber, 10),
      accountId
    }
  })

  if (trackingEvent.error) alert('Erro ao tentar salvar evento!')
  if (trackingEvent.loading) return <div className="events-list__loading"><MdRefresh /></div>
  if (trackingEvent.data) return <div className="events-list__check"><GoCheck /></div> 

  return (
    <button className="btn" onClick={onClickHandler}><GiSave /></button>
  )
}

export default AccountEventsUpdateList