import gql from 'graphql-tag'

export const LOGOUT = gql`
  mutation logout {
    logout @client
  }
`

export const LOGGED_IN_USER = gql`
  query loggedInUser {
    loggedInUser {
      name
      email
      picture
    }
  }
`

export const ALL_SECTIONS = gql`
  query allSections {
    allSections {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`