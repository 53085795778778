import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import _ from 'lodash'

import Chart from '../../components/Chart'
import MainContaintLoading from '../../components/MainContaintLoading'
import EditableNote from '../../components/EditableNote'

import EventsList from './EventsList'

import { ALL_ACCOUNT_EVENTS, SET_ACCOUNT_DESCRIPTION } from './gqls'

import { timeChartConfig, typeChartConfig } from './chartConfigs'
import { currencyFormat } from '../../helpers'
import * as dic from '../../helpers/accountDictionary'

const getAccount = query => query.loading ? '...' : query.data.account.number
const getUnit = query => query.loading ? '...' : query.data.account.unitCode
const getDetailing = query => query.loading ? '...' : dic.format(query.data.account.detailing)

const AccountEvents = () => {
  const { accountId } = useParams()
  const queryResponse = useQuery(ALL_ACCOUNT_EVENTS, {
    variables: { id: accountId }
  })

  return (
    <>
      <header className="header">
        Lista de contas {'>'} {getUnit(queryResponse)} {'>'} {getAccount(queryResponse)} {'>'} { getDetailing(queryResponse) }
      </header>
      <main className="main">
        <ComponentRenderer { ... queryResponse } />
      </main>
      <footer className="footer"></footer>
    </>
  )
}

const ComponentRenderer = ({ loading, data, error }) => {
  
  const [ setAccountDescription, trkgSetAccountDescription ] = useMutation(SET_ACCOUNT_DESCRIPTION)
  const [ showDescriptions, setShowDescriptions ] = useState(true)
  const [ filterByType, setFilterByType ] = useState(null)
  
  const timeChartOptions = 
    useMemo(() => (data ? timeChartConfig({ data: data.account.events.timeChartData }) : {}), [data])
  const typeChartOptions = 
    useMemo(() => (data ? typeChartConfig({ data: data.account.events.typeChartData }) : {}), [data])

  const saveDescription = description => setAccountDescription({
    variables: {
      accountId: data.account.id,
      description
    }
  })

  const filterByTypeHandler = e => setFilterByType(e.target.value)
  

  if (loading) return <MainContaintLoading />
  if (error) return <div>Erro ao tentar recuperar informações do servidor.</div>
  if (trkgSetAccountDescription.error) alert(trkgSetAccountDescription.error)

  if (data) return (
    <>
      <div className="auto-fit-grid">
        <div className="simple-card">
          <div className="simple-card__featured">{currencyFormat(data.account.balance)}</div>
          <div className="simple-card__description">
            { trkgSetAccountDescription.loading && <MainContaintLoading size="tiny"  /> }
            { !trkgSetAccountDescription.loading && !trkgSetAccountDescription.error && (
              <EditableNote value={data.account.description} onSave={saveDescription} />
            )}
          </div>              
        </div>
        <div className="simple-card simple-card--secondary">
          <div className="simple-card__featured">Elemento { data.account.detailing.slice(-10,-4) }</div>
          <div className="simple-card__description">{ _.capitalize(dic.element(data.account.detailing)) }</div>
        </div>  
        <div className="simple-card simple-card--secondary">
          <div className="simple-card__featured">Fonte { data.account.detailing.slice(-4) }</div>
          <div className="simple-card__description">{ _.capitalize(dic.source(data.account.detailing)) }</div>
        </div>
      </div>
      <div className="auto-fit-grid">
        <Chart
          options={ timeChartOptions } 
          style={{ maxHeight: '180px' }}
          className="simple-card"
        />
        <Chart 
          options={ typeChartOptions }
          style={{ maxHeight: '180px' }}
          className="simple-card"
        />
      </div>
      <div className="auto-fit-grid">
        <div className="nav-control">
          { (data.account.events.distinctTypes.length > 1) && (
            <div className="select">
              <select name="filterByType" onChange={filterByTypeHandler}>
                <option value="">Todos os tipos</option>
                { data.account.events.distinctTypes.map((type, idx) => <option value={type} key={idx}>{_.capitalize(type)}</option>) }
              </select>
              <div className="select__arrow"></div>
            </div>
          )}

          <div className="checkbox">
            <input type="checkbox" name="showDescriptions" checked={showDescriptions} onChange={ e => setShowDescriptions(e.target.checked) } />
            <label htmlFor="showDescriptions">&nbsp; Mostrar descrições</label>
          </div>
        </div>
      </div>
      <div className="auto-fit-grid">
        <EventsList 
          data={data.account.events.edges.slice(0).reverse()}
          filterByType={filterByType}
          showDescriptions={showDescriptions} />
      </div>
      <div className="auto-fit-grid">
      <div className="simple-card simple-card--secondary">
        <div className="simple-card__featured">Ação { data.account.detailing.slice(-18,-14) }</div>
          <div className="simple-card__description">{ dic.action(data.account.detailing) }</div>
        </div>
        <div className="simple-card simple-card--secondary">
          <div className="simple-card__featured">Subfunção { dic.subfunction(data.account.detailing).key }</div>
          <div className="simple-card__description">{ _.capitalize(dic.subfunction(data.account.detailing).description) }</div>
        </div>
        <div className="simple-card simple-card--secondary">
          <div className="simple-card__featured">Programa { dic.program(data.account.detailing).key }</div>
          <div className="simple-card__description">{ dic.program(data.account.detailing).description }</div>
        </div>
      </div>
    </>
  )
}


export default AccountEvents