import React, { useState, useEffect, useMemo } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import moment from 'moment'
import { MdAddToPhotos, MdDeleteForever } from 'react-icons/md'
import _ from 'lodash'

import { HIDE_TASK_FORM_MODAL, TASK_FORM_MODAL, ACQUISITION, UPDATE_TASK, SAVE_TASK } from './gqls'
import { ADD_ALERT } from '../../apollo/gqls'

const onError = error => console.log(error)
const inputDateFormat = 'YYYY-MM-DD'

const TaskFormModal = () => {
  // states
  const [ name, setName ] = useState('')
  const [ type, setType ] = useState('DEFINIDA_PELO_USUARIO')
  const [ planStartDate, setPlanStartDate ] = useState(moment().format(inputDateFormat))
  const [ planEndDate, setPlanEndDate ] = useState(moment().add(1, 'days').format(inputDateFormat))
  const [ doc, setDoc ] = useState('')
  const [ description, setDescription ] = useState('')
  const [ isCompleted, setIsCompleted ] = useState(false)
  const [ dependency, setDependency ] = useState('')
  const [ dependencies, setDependencies ] = useState([])

  // mutations
  const [ addAlert ] = useMutation(ADD_ALERT)
  const [ updateTask, updateTrack ] = useMutation(UPDATE_TASK, {
    onError,
    onCompleted: () => {
      addAlert({ variables: { message: 'Tarefa atualizada', type: 'SUCCESS_ALERT' } })
      setTimeout(() => hideTaskFormModal(), 500)
    }
  })
  const [ saveTask, saveTrack ] = useMutation(SAVE_TASK, {
    onError,
    onCompleted: () => {
      addAlert({ variables: { message: 'Tarefa criada', type: 'SUCCESS_ALERT' } })
      setTimeout(() => hideTaskFormModal(), 500)
    }
  })
  const [ hideTaskFormModal ] = useMutation(HIDE_TASK_FORM_MODAL)
  
  // queries
  const formQuery = useQuery(TASK_FORM_MODAL)
  const { isHidden, taskId, isCreating, acquisitionId } = formQuery.data.taskFormModal
  const acquisitionQeury = useQuery(ACQUISITION, {
    skip: !acquisitionId,
    variables: { id: acquisitionId },
    onError,
    
    onCompleted: data => {
      if (data && !isCreating) {
        const task = _.find(data.acquisition.tasks.edges, ({node}) => node.id === taskId)
        if (!task) return

        setName(task.node.name)
        setType(task.node.type)
        setPlanStartDate(moment(task.node.planStartDate).format(inputDateFormat))
        setPlanEndDate(moment(task.node.planEndDate).format(inputDateFormat))
        setDoc(task.node.doc || '')
        setDescription(task.node.description || '')
        setIsCompleted(task.node.isCompleted)
        setDependencies(task.node.dependencies.edges.map(({node}) => node.id))

      }
    }
  })
  
  useEffect(() => {
    if (isCreating) {
      setName('')
      setType('DEFINIDA_PELO_USUARIO')
      setPlanStartDate(moment().format(inputDateFormat))
      setPlanEndDate(moment().add(1, 'days').format(inputDateFormat))
      setDoc('')
      setDescription('')
      setIsCompleted(false)
      setDependencies([])
    }
  }, [isCreating])

  // helpers and handlers
  const isTypeDefinedByUser = type === 'DEFINIDA_PELO_USUARIO'
  const loading = acquisitionQeury.loading || saveTrack.loading || updateTrack.loading
  const tasks = _.get(acquisitionQeury, ['data', 'acquisition', 'tasks', 'edges'], [])

  const hashOfTasks = useMemo(() => {
    return tasks.reduce((acc, cur) => ({ ...acc, [cur.node.id]: cur }), {})
  }, [tasks])

  const cancelHandler = () => {
    hideTaskFormModal()
  }

  const changeTypeHandler = e => {
    setType(e.target.value)
  }

  const addDependencyHandler = e => {
    setDependencies([...dependencies, dependency])
  }
  
  const deleteDependencyHandler = id => {
    setDependencies(_.filter(dependencies, val => val !== id))
  }

  const updateHandler = () => {
    const variables = {
      name,
      doc,
      type,
      planStartDate: moment(planStartDate, inputDateFormat).toISOString(),
      planEndDate: moment(planEndDate, inputDateFormat).toISOString(),
      description,
      isCompleted,
      dependentTaskIds: dependencies
    }

    if (isCreating) saveTask({
      variables: { acquisitionId, ...variables }
    })
    else updateTask({
      variables: { id: taskId, ...variables }
    })
  }
  
  return (
    <div className={`modal-wrapper ${isHidden ? '' : 'show'}`}>
      <div className="modal">
        <div className="modal__header">Tarefa</div>
        <div className="grid grid--gap-lg grid--2">
          <div className="grid grid--1 col-1-tablet">
            <div className="form-field">
              <select disabled={loading} name="type" className="form-field__select" required value={type} onChange={changeTypeHandler}>
                <option value="DEFINIDA_PELO_USUARIO">Definida pelo usuário</option>
                <option value="REPASSE_COTA_ORCAMENTARIA">Repasse de cota orçamentária</option>
                <option value="PEDIDO_NO_COMPRAS">Pedido no compras</option>
                <option value="PROCESSO_DE_COMPRAS">Processo de compras</option>
              </select>
              <label htmlFor="type" className="form-field__label">Tipo de tarefa</label>
            </div>
            <div className={`form-field hidden ${isTypeDefinedByUser ? 'show' : ''}`} >
              <input disabled={loading} type="text" name="name" className="form-field__input" required value={name} onChange={e => setName(e.target.value)} />
              <label htmlFor="name" className="form-field__label">Nome</label>
            </div>
            <div className="form-field">
              <label className="form-field__label" htmlFor="planStartDate">Data prevista de início</label>
              <input disabled={loading} className="form-field__input" type="date" name="planStartDate" value={planStartDate} onChange={e => setPlanStartDate(e.target.value)} />
            </div>
            <div className="form-field">
              <input disabled={loading} className="form-field__input" type="date" name="planEndDate" min={planStartDate} value={planEndDate} onChange={e => setPlanEndDate(e.target.value)} />
              <label className="form-field__label" htmlFor="planEndDate">Data prevista de conclusão</label>
            </div>
            <div className="form-field">
              <input disabled={loading} className="form-field__input" type="number" name="doc" value={doc} onChange={e => setDoc(e.target.value)} />
              <label htmlFor="doc" className="form-field__label">Número do documento no SEI</label>
            </div>
            {!isCreating && (
              <div className="form-field form-field--row">
                <input disabled={loading} type="checkbox" name="isCompleted" className="form-field__checkbox" checked={isCompleted} onChange={e => setIsCompleted(e.target.checked)} />
                <label htmlFor="isCompleted" className="form-field__label">Tarefa finalizada</label>
              </div>
            )}
            
          </div>
          <div className="grid grid--1 col-1-tablet">
            <div className="form-field">
              <textarea disabled={loading} className="form-field__textarea" name="description" rows="5" value={description} onChange={e => setDescription(e.target.value)} />
              <label className="form-field__label" htmlFor="description">Descrição</label>
            </div>
            <div className="form-field form-field--inline-btn">
              <select disabled={loading} name="dependency" className="form-field__select" required value={dependency} onChange={e => setDependency(e.target.value)}>
                <option value=""></option>
                {tasks.length && tasks.map(({ node }, idx) => (
                  <option key={idx} value={node.id}>{node.name}</option>
                ))}
              </select>
              <label htmlFor="dependency" className="form-field__label">Dependência</label>
              <button disabled={dependency === '' || loading} className="btn-icon" onClick={addDependencyHandler}><MdAddToPhotos /></button>
            </div>
            <ul className="credit-events-list">
              {dependencies.map(id => (
                <React.Fragment key={id}>
                  { hashOfTasks[id] && (
                    <div className="credit-events-list__item">
                      <div className="credit-events-list__date">{hashOfTasks[id].node.name}</div>
                      <div className="credit-events-list__actions">
                        <button disabled={loading} className="btn-icon" onClick={() => deleteDependencyHandler(id)}><MdDeleteForever /></button>
                      </div>
                    </div>
                  ) }
                </React.Fragment>
              ))}
            </ul>
          </div>
          <div className="form-actions">
            <button disabled={loading} className="btn" onClick={updateHandler}>{isCreating ? 'Salvar' : 'Atualizar'}</button>
            <button disabled={loading} className="btn" onClick={cancelHandler}>Cancelar</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TaskFormModal