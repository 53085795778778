import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import moment from 'moment'
import _ from 'lodash'

import { SAVE_ACQUISITION, ACQUISITION_FORM_MODAL, HIDE_ACQUISITION_FORM_MODAL, ACQUISITION, UPDATE_ACQUISITION } from './gqls'
import { ALL_ACQUISITIONS } from '../../pages/Section/gqls'
import Loading from '../../components/Loading'

const df = 'YYYY-MM-DD'

const getDeadlineFor = type => {
  switch (type) {
    case 'PREGAO_ELETRONICO':
      return 30
    case 'COTEP':
      return 15
    case 'REGISTRO_DE_PRECO':
      return 10
    case 'ADITIVO_CONTRATUAL':
      return 10
    case 'DISPENSA_E_INEXIGIBILIDADE':
      return 20
    default:
      return 20
  }
}

const calcEndDate = 
  (taskType, startDate = moment().format(df)) => 
    moment(startDate, df).add(getDeadlineFor(taskType), 'days').format(df)

const initialState = {
  name: '',
  description: '',
  planStartDate: undefined,
  planEndDate: undefined,
  type: 'PREGAO_ELETRONICO'
}

const onError = error => console.log(error)

const AcquisitionFormModal = () => {

  // states
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [planStartDate, setPlanStartDate] = useState(moment().format(df))
  const [type, setType] = useState(initialState.type)
  const [planEndDate, setPlanEndDate] = useState(calcEndDate(initialState.type))

  const resetState = (acquisition = initialState) => {
    setName(acquisition.name)
    setDescription(acquisition.description)
    setPlanStartDate(moment(acquisition.planStartDate).format(df))
    setType(acquisition.type)
    
    let planEndDate = 
      acquisition.planEndDate ? 
        moment(acquisition.planEndDate).format(df) : calcEndDate(acquisition.type)
    
    setPlanEndDate(planEndDate)
  }

  // queries
  const { data } = useQuery(ACQUISITION_FORM_MODAL)
  const { isHidden, sectionId, isCreating, acquisitionId } = data.acquisitionFormModal
  const acquisitionQuery = useQuery(ACQUISITION, { 
    skip: !acquisitionId || isCreating,
    variables: { id: acquisitionId },
    onError,
    onCompleted: data => {
      if (data) resetState(data.acquisition)
    }
  })

  // mutations
  const onCompleted = () => {
    setTimeout(() => hideAcquisitionModal(), 500)
    resetState() 
  }
  const [hideAcquisitionModal] = useMutation(HIDE_ACQUISITION_FORM_MODAL)
  const [saveAcquisition, saveAcquisitionTrack ] = useMutation(SAVE_ACQUISITION, { 
    onError, 
    onCompleted,
    update: (cache, { data }) => {
      const query = {
        query: ALL_ACQUISITIONS,
        variables: {
          section: sectionId
        }
      }

      const cacheData = _.cloneDeep(cache.readQuery(query))
      cacheData.allAcquisitions.edges = [
        {
          node: data.saveAcquisition,
          __typename: "AcquisitionEdge"
        },
        ...cacheData.allAcquisitions.edges
      ]

      cache.writeQuery({
        ...query,
        data: cacheData
      })
    }
  })
  const [updateAcquisition, updateAcquisitionTrack] = useMutation(UPDATE_ACQUISITION, {
    onError,
    onCompleted
  })

  // handlers and helpers
  const saveAcquisitionHandler = () => {
    if (isCreating)
      saveAcquisition({ variables: { name, description, planEndDate, planStartDate, type, sectionId } })
    else
      updateAcquisition({ variables: { id: acquisitionId, name, description, planEndDate, planStartDate } })
  }

  const planStartDateHandler = e => {
    setPlanStartDate(e.target.value)
    setPlanEndDate(calcEndDate(type, e.target.value))
  }
  const planEndDateHandler = e => setPlanEndDate(e.target.value)
  const typeHandler = e => {
    setType(e.target.value)
    setPlanEndDate(calcEndDate(e.target.value, planStartDate))
  }
  const cancelAcquisitionHandler = e => {
    hideAcquisitionModal()
    resetState()
  }

  const isPlanEndDateReadOnly = (type !== 'NAO_DEFINIDO')
  const loading = saveAcquisitionTrack.loading || acquisitionQuery.loading || updateAcquisitionTrack.loading

  return (
    <div className={`modal-wrapper ${isHidden?'':'show'}`}>
      <div className="modal">
        <div className="modal__header">Nova aquisição</div>
        <div className="grid grid--gap-sm grid--2">
          <div className="form-field col-1-tablet">
            <label className="form-field__label" htmlFor="name">Nome</label>
            <input disabled={loading} className="form-field__input" type="text" name="name" value={name} onChange={e => setName(e.target.value)} placeholder="Nome" />
          </div>
          <div className="form-field col-1-tablet">
            <label className="form-field__label" htmlFor="type">Tipo de processo</label>
            <select disabled={loading || !isCreating} className="form-field__select" name="type" value={type} onChange={typeHandler}>
              <option value="NAO_DEFINIDO">Não definido</option>
              <option value="PREGAO_ELETRONICO">Pregão eletrônico</option>
              <option value="COTEP">Cotep</option>
              <option value="REGISTRO_DE_PRECO">Registro de preço</option>
              <option value="ADITIVO_CONTRATUAL">Aditivo contratual</option>
              <option value="DISPENSA_E_INEXIGIBILIDADE">Dispensa ou inexigibilidade</option>
            </select>
          </div>
          <div className="form-field col-1-tablet">
            <label className="form-field__label" htmlFor="planStartDate">Data prevista de início</label>
            <input disabled={loading} className="form-field__input" type="date" name="planStartDate" value={planStartDate} onChange={planStartDateHandler} />
          </div>
          <div className="form-field col-1-tablet">
            <label className="form-field__label" htmlFor="planEndDate">Data prevista de conclusão</label>
            <input disabled={loading} className="form-field__input" readOnly={isPlanEndDateReadOnly} type="date" name="planEndDate" min={planStartDate} value={planEndDate} onChange={planEndDateHandler} />
          </div>
          <div className="form-field">
            <label className="form-field__label" htmlFor="description">Descrição</label>
            <textarea disabled={loading} className="form-field__textarea" name="description" rows="5" value={description} onChange={e => setDescription(e.target.value)} />
          </div>
          <div className={`hidden ${loading?'show':''}`}>
            <Loading />
          </div>
          <div className="form-actions">
            <button disabled={loading} className="btn" onClick={cancelAcquisitionHandler}>Cancelar</button>
            <button disabled={loading} className="btn" onClick={saveAcquisitionHandler}>{isCreating? 'Salvar' : 'Atualizar'}</button>
          </div>
        </div>
      </div>
    </div>
  )
}



export default AcquisitionFormModal