import React,{ useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'
import { ALL_SECTIONS } from './gqls'

import Profile from './Profile'

const SideNav = () => {
  const [ isMenuOpen, setIsMenuOpen ] = useState(false)

  return (
    <>
      <div className="menu-icon" onClick={() => setIsMenuOpen(true)}><AiOutlineMenu /></div>
      <aside className={'sidenav ' + (isMenuOpen ? 'active' : '')}>
        <div className="sidenav__close-icon" onClick={() => setIsMenuOpen(false)}>
          <AiOutlineClose />
        </div>

        <Profile />
        <MainMenu />
        <SectionsMenu />

      </aside>
    </>
  )
}

const ListItem = ({ to, label, ...args }) => (
  <li className="sidenav__list-item" {...args}>
    <Link to={to} className="sidenav__list-link">{label}</Link>
  </li>
)

const MainMenu = () => (
  <ul className="sidenav__list" data-title="Principal">
    <ListItem to="/" label="Início" />
    <ListItem to="/reports" label="Relatórios" />
    <ListItem to="/accounts-list" label="Lista de contas" />
    <ListItem to="/account-update" label="Atualizar contas" />
  </ul>
)

const onError = error => console.log(error)

const SectionsMenu = () => {
  const { data, loading } = useQuery(ALL_SECTIONS, { onError })

  if (loading) return <></>

  return (
    <ul className="sidenav__list" data-title="Setores">
      {data && data.allSections.edges.map(({ node }) => (
        <ListItem
          key={node.id}
          to={`/sections/${node.id}`}
          label={node.name} />
      ))}
    </ul>
  )
}

export default SideNav