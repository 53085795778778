import React from 'react'
import moment from 'moment'
import { FiEdit } from 'react-icons/fi'
import { MdDeleteForever } from 'react-icons/md'
import { useMutation } from '@apollo/react-hooks'

import { SHOW_TASK_FORM_MODAL, SHOW_GANTT_MODAL, DELETE_TASK } from '../gqls'
import { ADD_ALERT } from '../../../apollo/gqls'

const TasksList = ({ data, acquisitionId }) => {
  const [ showTaskFormModal ] = useMutation(SHOW_TASK_FORM_MODAL)
  
  const showFormModalHandler = taskId => {
    showTaskFormModal({ variables: { taskId, acquisitionId } })
  } 

  return (
    <div className="simple-card simple-card--fit-content">
      <div className="simple-card__featured">Tarefas</div>
      <div className="simple-card__actions">
        <button className="btn-icon" onClick={() => showFormModalHandler()}>Nova tarefa</button>
        {(data.length > 0) && <ShowGanttButton data={data} />}
      </div>
      <div className="simple-card__description scroll-list-wrapper">
        <ul className="credit-events-list">
          {data.map(({ node }) => (
            <div className="credit-events-list__item" key={node.id}>
              <div className={`credit-events-list__doc ${node.isCompleted? 'crossed': ''}`}>{node.name}</div>
              <div className="credit-events-list__date">{moment(node.planStartDate).format('DD/MM/YYYY')} - {moment(node.planEndDate).format('DD/MM/YYYY')}</div>
              { node.doc &&  <div className="credit-events-list__value">Documento {node.doc}</div> }
              <div className="credit-events-list__actions">
                <DeleteTaskButton id={node.id} />
                <button className="btn-icon" onClick={() => showFormModalHandler(node.id)}><FiEdit /></button>
                {/* <button className="btn-icon"><FiLink2 /></button> */}
              </div>
            </div>
          ))}
        </ul>
      </div>
    </div>
  )
}

const onError = error => console.log(error)

const DeleteTaskButton = ({ id }) => {
  const [ addAlert ] = useMutation(ADD_ALERT)
  const [ deleteTask ] = useMutation(DELETE_TASK, {
    variables: { id },
    onError,
    onCompleted: () => {
      addAlert({ variables: { message: "Tarefa removida", type: "SUCCESS_ALERT"}})
    }
  })
  return <button className="btn-icon" onClick={() => deleteTask()}><MdDeleteForever /></button>
}

const ShowGanttButton = ({data}) => {
  const [showGanttButton] = useMutation(SHOW_GANTT_MODAL, {variables: {data}})
  return <button className="btn-icon" onClick={() => showGanttButton()}>Gráfico Gantt</button>
}

export default TasksList