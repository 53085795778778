import { gql } from 'apollo-boost'

export const SHOW_TASK_FORM_MODAL = gql`
  mutation ShowTaskFormModal($taskId: ID, $acquisitionId: ID) {
    showTaskFormModal(taskId: $taskId, acquisitionId: $acquisitionId) @client
  }
`

export const SHOW_GANTT_MODAL = gql`
  mutation ShowGanttModal ($data: [Node]!) {
    showGanttModal(data: $data) @client
  }
`

export const DELETE_TASK = gql`
  mutation DeleteTask($id: ID!) {
    deleteTask(id: $id) {
      id
      startDate
      endDate
      planStartDate
      planEndDate
      tasks {
        edges {
          node {
            id
            startDate
            endDate
            planStartDate
            planEndDate
            isCompleted
            name
            description
            doc
            dependencies {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`

export const ALL_UNITIES = gql`
  query AllUnities {
    allUnities (order: NAME__ASC) {
      edges {
        node {
          id
          name
          city
        }
      }
    }
  }
`

export const ASSOCIATE_UNITY = gql`
  mutation AssociateUnity ($acquisitionId: ID!, $unityId: ID!) {
    associateUnity(input: {
      acquisitionId: $acquisitionId
      unityId: $unityId
    }) {
      id
      benefitedUnities {
        edges {
          node {
            id
            name
            city
          }
        }
      }
    }
  }
`

export const DISASSOCIATE_UNITY = gql`
  mutation DisassociateUnity ($acquisitionId: ID!, $unityId: ID!) {
    disassociateUnity(input: {
      acquisitionId: $acquisitionId
      unityId: $unityId
    }) {
      id
      benefitedUnities {
        edges {
          node {
            id
            name
            city
          }
        }
      }
    }
  }
`

export const ACQUISITION = gql`
  query Acquisition ($id: ID!) {
    acquisition(id: $id) {
      id
      startDate
      endDate
      planStartDate
      planEndDate
      description
      name
      type
      totalIn @client
      totalOut @client
      associatedAccountEvents {
        event {
          id
          registryDate
          type
          account {
            id
            detailing
          }
        }
        id
        value
        description
      }
      section {
        id
        name
      }
      benefitedUnities {
        edges {
          node {
            id
            name
            city
          }
        }
      }
      tasks {
        edges {
          node {
            id
            startDate
            endDate
            planStartDate
            planEndDate
            isCompleted
            name
            description
            doc
            dependencies {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`