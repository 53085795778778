import { gql } from 'apollo-boost'

export const TASK_FORM_MODAL = gql`
  query TaskFormModal {
    taskFormModal @client {
      isHidden
      taskId
      isCreating
      acquisitionId
    }
  }
`

export const HIDE_TASK_FORM_MODAL = gql`
  mutation HideTaskFormModal {
    hideTaskFormModal @client
  }
`

export const ACQUISITION = gql`
  query Acquisition($id: ID!) {
    acquisition(id: $id) {
      id
      tasks {
        edges {
          node {
            id
            name
            doc
            type
            planStartDate
            planEndDate
            description
            isCompleted
            dependencies {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`
// export const TASK = gql`
//   query Task($id: ID!) {
//     task(id: $id) {
//       id
//       name
//       doc
//       type
//       planStartDate
//       planEndDate
//       description
//       isCompleted
//     }
//   }
// `

export const UPDATE_TASK = gql`
  mutation UpdateTask(
    $id: ID!
    $name: String!
    $doc: String!
    $type: TaskType!
    $planStartDate: Date!
    $planEndDate: Date!
    $description: String!
    $isCompleted: Boolean!
    $dependentTaskIds: [ID]!
  ) {
    updateTask (input: {
      id: $id
      name: $name
      doc: $doc
      type: $type
      dependentTaskIds: $dependentTaskIds
      planStartDate: $planStartDate
      planEndDate: $planEndDate
      description: $description
      isCompleted: $isCompleted
    }) {
      id
      name
      doc
      type
      planStartDate
      planEndDate
      description
      isCompleted
      dependencies {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`

export const SAVE_TASK = gql`
  mutation SaveTaskt(
    $name: String!
    $doc: String!
    $type: TaskType!
    $planStartDate: Date!
    $planEndDate: Date!
    $description: String!
    $isCompleted: Boolean!
    $acquisitionId: ID!
    $dependentTaskIds: [ID]!
  ) {
    saveTask (input: {
      acquisitionId: $acquisitionId
      name: $name
      doc: $doc
      type: $type
      dependentTaskIds: $dependentTaskIds
      planStartDate: $planStartDate
      planEndDate: $planEndDate
      description: $description
      isCompleted: $isCompleted
    }) {
      id
      startDate
      endDate
      planStartDate
      planEndDate
      tasks {
        edges {
          node {
            id
            startDate
            endDate
            planStartDate
            planEndDate
            isCompleted
            name
            description
            doc
            dependencies {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`