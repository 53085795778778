import React, {useState, useEffect} from 'react'
import { useQuery } from '@apollo/react-hooks'

import { ALL_SECTIONS, ALL_AQUISITIONS } from '../gqls'

const onError = error => console.log(error)

const SelectAcquisition = ({ onChange, eventId }) => {
  const [section, setSection] = useState('')
  const [acquisition, setAcquisition] = useState('')

  const sctResult = useQuery(ALL_SECTIONS, { onError })
  const acqResult = useQuery(ALL_AQUISITIONS, {
    skip: section === '',
    variables: { section },
    onError
  })

  const resetState = () => {
    onChange(null)
    setSection('')
    setAcquisition('')
  }

  const loading = (sctResult.loading && acqResult.loading)

  const sectionHandler = e => {
    setSection(e.target.value)
    setAcquisition('')
    sctResult.refetch()
  }
  const acquisitionHandler = e => setAcquisition(e.target.value)

  useEffect(() => onChange(acquisition), [acquisition, onChange])
  useEffect(() => {
    resetState()
  }, [eventId])

  const isSectionEmpty = (section === '')

  return (
    <>
      <div className="form-field col-1-tablet">
        <select disabled={loading} required className="form-field__select" name="section" value={section} onChange={sectionHandler}>
          <option value=""></option>
          {sctResult.data && sctResult.data.allSections.edges.map(({ node }) => (
            <option value={node.id} key={node.id}>{node.name}</option>
            ))}
        </select>
        <label className="form-field__label" htmlFor="section">Setor</label>
      </div>
      <div className="form-field col-1-tablet">
        <select disabled={loading || isSectionEmpty} required className="form-field__select" name="acquisition" value={acquisition} onChange={acquisitionHandler}>
          <option value=""></option>
          {!isSectionEmpty && acqResult.data && acqResult.data.allAcquisitions.edges.map(({ node }) => (
            <option value={node.id} key={node.id}>{node.name}</option>
            ))}
        </select>
        <label className="form-field__label" htmlFor="acquisition">Procedimento</label>
      </div>
    </>
  )
}

export default SelectAcquisition