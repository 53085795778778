const dev = {
  graphql: {
    URL: "http://localhost:32099/graphql",
    SUBSCRIPTION_URL: "ws://localhost:32099/graphql"
  },
  xls2json: {
    URL: "http://localhost:32099/xls2json"
  }
}

// const prod = {
//   graphql: {
//     URL: "http://csmgraphql.herokuapp.com/graphql",
//     SUBSCRIPTION_URL: "ws://csmgraphql.herokuapp.com/graphql"
//   },
//   xls2json: {
//     URL: "http://csmgraphql.herokuapp.com/xls2json"
//   }
// }

const prod = {
  graphql: {
    URL: "https://csmorcamento.bombeiros.mg.gov.br/graphql",
    SUBSCRIPTION_URL: "wss://csmorcamento.bombeiros.mg.gov.br/graphql"
  },
  xls2json: {
    URL: "https://csmorcamento.bombeiros.mg.gov.br/xls2json"
  }
}

const config = process.env.NODE_ENV === 'production' ? prod : dev

export default {
  ...config,
  google: {
    CLIENT_ID: "46978641145-6d2qg82ltnel2tth2f0qog83e6je3voe.apps.googleusercontent.com"
  }
}