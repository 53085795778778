import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { DISASSOCIATE_ACCOUNT_EVENT } from '../gqls'
import { ADD_ALERT } from '../../../apollo/gqls'
import { currencyFormat } from '../../../helpers'
import { MdDeleteForever } from 'react-icons/md'

const onError = error => console.log(error)

const AssociationList = ({ associations }) => {
  const [addAlert] = useMutation(ADD_ALERT)
  const [disassociate] = useMutation(DISASSOCIATE_ACCOUNT_EVENT, {
    onError,
    onCompleted: () => {
      addAlert({ variables: { message: "Vinculo de valor removido", type: "SUCCESS_ALERT" } })
    }
  })

  const disassociateHandler = ({ id, acquisition }) => disassociate({
    variables: {
      associationId: id,
      acquisitionId: acquisition.id
    }
  })

  return (
    <>
      <ul className="unstyled-list associated-events-list">
        {associations.map(data => (
          <li key={data.id} className="associated-events-list__item">
            <div className="associated-events-list__value">{currencyFormat(data.value)}</div>
            <div className="associated-events-list__name">{data.acquisition.name}</div>
            <div className="associated-events-list__actions">
              <button className="btn-icon" onClick={() => disassociateHandler(data)}><MdDeleteForever /></button>
            </div>
          </li>
        ))}
      </ul>
      {!associations.length && <div className="associated-events-list__empty">Nenhum valor atribuido</div>}
    </>
  )
}

export default AssociationList