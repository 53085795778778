// https://dev.to/n8tb1t/tracking-scroll-position-with-react-hooks-3bbj
import { useState, useEffect, useRef } from 'react'
import { debounce } from 'lodash'

const getScrollPosition = element => ({ 
  x:  {
    position: element.scrollTop,
    size: (element.scrollHeight - element.offsetHeight),
    isAtTheEnd: element.scrollTop === (element.scrollHeight - element.offsetHeight),
    isAtTheTop: element.scrollTop === 0
  }
})

function useScrollInfo({ effect, wait = 0 }) {  
  const ref = useRef(null)
  const [position, setPosition] = useState(null)

  const callback = debounce(element => {
    const currPos = getScrollPosition(element)
    effect({ prevPos: position, currPos })
    setPosition(currPos)
  }, wait)
  
  useEffect(() => {
    const refDom = ref.current
    if (!refDom) return

    const handleScroll = () => callback(refDom)
    refDom.addEventListener('scroll', handleScroll)

    return () => refDom.removeEventListener('scroll', handleScroll)
  }, [callback])

  return ref
}

export default useScrollInfo
