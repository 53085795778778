import React from 'react'
import moment from 'moment'
import _ from 'lodash'

import { options, rowY } from '../helpers'

const GridLayer = ({ width, countTasks, minDate }) => {
  const { headerHeight, barHeight, padding } = options

  const todayX = moment().diff(minDate, 'days')
  const gridHeight = headerHeight + padding + (barHeight + padding) * countTasks

  return (
    <g className="grid"> {/* grid layer */}
      <GridBackground width={width} height={gridHeight} />
      <g> {/* rows layer */}
        {_.range(countTasks).map((val, idx) => (
          <GridRow idx={idx} width={width} key={idx} />
        ))}
      </g>
      <g> {/* lines layer */}
        {_.range(countTasks).map((val, idx) => (
          <RowLine idx={idx} width={width} key={idx} />
        ))}
      </g>
      <GridHeader width={width} />
      <TodayHighlight idx={todayX} height={gridHeight - padding} />
    </g>
  )
}

export default GridLayer

const GridBackground = ({ width, height }) => <rect x='0' y='0' width={width} height={height} className='grid-background' />

const GridHeader = ({ width }) => {
  const { headerHeight } = options
  return (
    <rect
      x='0'
      y='0'
      width={width}
      height={headerHeight + 10}
      className="grid-header" />
  )
}

const GridRow = ({ idx, width }) => {
  const { headerHeight, barHeight, padding } = options
  const setRowY = idx => headerHeight + (padding / 2) + (idx * (barHeight + padding))

  return (
    <rect
      x='0'
      y={setRowY(idx)}
      width={width}
      height={barHeight + padding}
      className='grid-row' />
  )
}

const RowLine = ({ idx, width }) => {
  const { barHeight, padding } = options
  const y = rowY(idx) + (barHeight + padding)

  return (
    <line
      x1='0'
      y1={y}
      x2={width}
      y2={y}
      className='row-line' />
  )
}

const TodayHighlight = ({ idx, height }) => {
  const { columnWidth } = options

  return (
    <rect
      x={idx * columnWidth}
      y='0'
      width={columnWidth}
      height={height}
      className="today-highlight" />
  )
}