import { gql } from 'apollo-boost'

export const ALL_ACCOUNT_EVENTS = gql`
  query account ($id: ID!) {
    account(id: $id) {
      id
      detailing
      unitCode
      number
      balance
      description
      events(order: REGISTRY_DATE__ASC) {
        edges {
          node {
            id
            value
            balance
            documentNumber
            registryDate
            unitOrigin
            description
            type
          }
        }
        timeChartData @client {
          x
          y
        }
        typeChartData @client {
          values
          labels
        }
        distinctTypes @client
      }
    }
  }
`

export const SET_ACCOUNT_DESCRIPTION = gql`
  mutation SetAccountDescriprion(
    $accountId: ID!
    $description: String!
  ){
    setAccountDescription(input:{
      accountId: $accountId
      description: $description
    }) {
      id
      description
    }
  }
`

export const SHOW_ACCOUNT_EVENT_MODAL = gql`
  mutation ShowAccountEventModal($eventId: ID!) {
    showAccountEventModal(eventId: $eventId) @client
  }
`

export const SHOW_ACCOUNT_EVENT_DESCRIPTION_MODAL = gql`
  mutation ShowAccountEventDescriptionModal($eventId: ID!) {
    showAccountEventDescriptionModal(eventId: $eventId) @client
  }
`