import React from 'react'
import { MdRefresh } from 'react-icons/md'

const Loading = () => (
  <div className="loading">
    <MdRefresh className="loading__spin" />
  </div>
)

export default Loading

