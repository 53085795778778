import React, { useState, useMemo } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useHistory, Link } from 'react-router-dom'
import { useGoogleLogin } from 'react-google-login'

import { LOGIN, SET_TOKENS, LOGIN_WITH_GOOLGE } from './gqls'
import MainContaintLoading from '../../components/MainContaintLoading'
import config from '../../config'

const onError = error => console.log(error)
const onFailure = onError

const Login = () => {
  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')
  const history = useHistory()

  const [ setTokens ] = useMutation(SET_TOKENS)
  
  const onCompleted = async data => {
    const loginData = data.login || data.loginWithGoogle
    await setTokens({ variables: { ...loginData } })
    history.push('/')
  }
  
  const [ loginWithGoogle, gLoginTracking ] = useMutation(LOGIN_WITH_GOOLGE, { onError, onCompleted })
  const [ login, loginTracking] = useMutation(LOGIN, { onError, onCompleted })
  
  const { signIn, loaded } = useGoogleLogin({
    clientId: config.google.CLIENT_ID,
    cookiePolicy: 'single_host_origin',
    onSuccess: data => loginWithGoogle({ variables: { token: data.tokenId } }),
    onFailure,
    onError
  })

  const { loading } = useMemo(() => ({    
    loading: (loginTracking.loading || gLoginTracking.loading)
  }), [loginTracking, gLoginTracking])

  const loginHandle = e => {
    e.preventDefault()
    login({ variables: { email, password } })
  }

  const loginGoogleHandler = e => {
    e.preventDefault()
    signIn()
  }

  return (
    <div className={`login-page ${loading? 'loading' : ''}`} >
      <div className="forms-wrapper">
        <form className="login-form" onSubmit={loginHandle}>
          <input disabled={loading} type="text" placeholder="e-mail" value={email} onChange={e => setEmail(e.target.value)} />
          <input disabled={loading} type="password" placeholder="senha" value={password} onChange={e => setPassword(e.target.value)} />
          <button disabled={loading}>
            { loading ? <MainContaintLoading size="tiny" /> : 'Entrar' }
          </button>
          {loaded && <p className="message">Não possui registro? <Link to="#" onClick={loginGoogleHandler}>Entrar com conta Google corporativa</Link></p> }
        </form>
      </div>
    </div>
  )
}

export default Login