import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import _ from 'lodash'

import AcquisitionCard from './AcquisitionCard'
import MainContaintLoading from '../../components/MainContaintLoading'
import { ALL_ACQUISITIONS, SHOW_ACQUISITION_FORM_MODAL, SHOW_GANTT_MODAL} from './gqls'
// import { acquisitionAdded, acquisitionDeleted } from './subscribes'

const onError = error => console.log(error)

const Section = () => {
  const { sectionId } = useParams()
  const allAcquisitionsQuery = useQuery(ALL_ACQUISITIONS, { 
    variables: {
      section: sectionId
    }, 
    onError
  })
  // const subscribeToMore = allAcquisitionsQuery.subscribeToMore
  
  // useEffect(() => {
  //   [acquisitionAdded, acquisitionDeleted].map(config => subscribeToMore(config))
  // }, [subscribeToMore])

  const name = _.get(allAcquisitionsQuery, ['data', 'section', 'name'], '')

  return (
    <>
      <header className="header">
        {name? `Seção > ${name}`: ''}
      </header>
      <main className="main">
        <ComponentRenderer {...allAcquisitionsQuery} />
      </main>
      <footer className="footer"></footer>
    </>
  )
}

const ComponentRenderer = ({ data, loading }) => {
  const acquisitions = _.get(data, ['allAcquisitions', 'edges'], [])
  const sectionId = _.get(data, ['section', 'id'], '')

  if (loading) return <MainContaintLoading />
  
  return (
    <>
      <div className="auto-fit-grid">
        <div className="nav-control">
          <ShowModalButton sectionId={sectionId} />
          { (acquisitions.length > 0) && <ShowGanttModalButton data={acquisitions} /> }
        </div>
      </div>
      <div className="auto-fill-grid auto-fill-grid--large">
        {acquisitions.map(({ node }) => (
          <AcquisitionCard key={node.id} sectionId={sectionId} {...node} />
        ))}
      </div>
    </>
  )
}

const ShowModalButton = ({ sectionId }) => {
  const [showAcquisitionModal] = useMutation(SHOW_ACQUISITION_FORM_MODAL, {
    variables: { sectionId }
  })

  return (
    <button className="btn" onClick={() => showAcquisitionModal()}>Novo procedimento</button>
  )
}

const ShowGanttModalButton = ({ data }) => {
  const [showGanttModal] = useMutation(SHOW_GANTT_MODAL, {
    variables: { data }
  })

  return <button className="btn" onClick={() => showGanttModal()}>Mostrar Gantt</button>
}

export default Section