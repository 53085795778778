import { gql } from 'apollo-boost'

export const GANTT_MODAL = gql`
  query GanttModal {
    ganttModal @client {
      isHidden
      tasks {
        id
        name
        start
        end
        progress
        dependencies
      }
    }
  }
`

export const HIDE_GANTT_MODAL = gql`
  mutation HideGanttModal {
    hideGanttModal @client
  }
`