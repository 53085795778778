import { gql } from 'apollo-boost'

export const ACQUISITIONS_PER_UNITY = gql`
  query AcquisitionsPerUnity {
    acquisitionsPerUnity {
      id
      acquisitionId
      planStartDate
      planEndDate
      name
      unity {
        name
        city
      }
      associatedAccountEvents {
        value
      }
      tasks (
        first: 1
        filter: {
          isCompleted: false
        }
        order: PLAN_START_DATE__ASC
      ) {
        edges {
          node {
            name
          }
        }
      }
    }
  }
`