import React from 'react'
import { Link } from 'react-router-dom'

const Reports = () => {
  return (
    <>
      <header className="header"></header>
      <main className="main">
        <div className="auto-fill-grid">
          <Link className="accounts-list-card" to='/reports/credits'>
            <div className="accounts-list-card__sum">Créditos repassados</div>
          </Link>
          <Link className="accounts-list-card" to='/reports/unities'>
            <div className="accounts-list-card__sum">Unidades</div>
          </Link>
        </div>
      </main>
      <footer className="footer"></footer>
    </>
  )
}

export default Reports
