import { useRef, useEffect } from 'react'
import { debounce } from 'lodash'

const isBrowser = typeof window !== 'undefined'

const getScrollPosition = () => {
  if (!isBrowser) return { x: 0, y: 0 }

  const position = document.body.getBoundingClientRect()

  return { x: position.left, y: position.top } 
}

function useWindowScrollInfo({ effect, wait = 0 }) {  
  const position = useRef({ x: 0, y: 0 })

  const callback = debounce(() => {
    const currPos = getScrollPosition()
    effect({ prevPos: position.current, currPos })
    position.current = currPos
  }, wait)
  
  useEffect(() => {
    const handleScroll = () => callback()
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [callback])
}

export default useWindowScrollInfo
