import React,{ useEffect, useState } from 'react'
import { set, keys, subtract, union, has, concat, toLower, upperFirst } from 'lodash'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { MdRefresh, MdContentCopy } from 'react-icons/md'

import { currencyFormat, copyToClipboard } from '../../../helpers'
import { element as elementDic, format } from '../../../helpers/accountDictionary'

const ACCOUNTS_CREDIT_BALANCE = gql`
  query AccountesCreditBalance($unit: String, $number: String){
    allAccounts(first: 100, filter: {
      balance_gt: 0,
      unitCode: $unit,
      number: $number
    }) {
      edges {
        node {
          id
          balance
          detailing
          unitCode
          number
        }
      }
    }
  }
`

const ADD_ALERT = gql`
  mutation AddAlert($type: AlertClientEnum, $message: String){
    addAlert(type: $type, message: $message) @client
  }
`

const AccountComparation = ({ unit, account, balances }) => {
  const [ comparation, setComparation ] = useState([])
  const [ onlyDiffs, setOnlyDiffs ] = useState(true)
  
  const [ addAlert ] = useMutation(ADD_ALERT)
  const { loading, error, data, refetch, networkStatus } = useQuery(ACCOUNTS_CREDIT_BALANCE, {
    variables: { unit, number: account },
    notifyOnNetworkStatusChange: true
  })

  useEffect(() => {
    if (!data) return

    const mappedData = data.allAccounts.edges.reduce((acc, { node }) => set(acc, node.detailing, node.balance), {})
    const keyUnion = union(keys(mappedData), keys(balances))

    const comparation = keyUnion.reduce((acc, key) => {
      return concat(acc, [{
        account: key,
        former: has(mappedData, key) ? mappedData[key] : 0.0,
        reference: has(balances, key) ? balances[key] : 0.0
      }])
    }, [])

    setComparation(comparation)
  }, [data, balances])

  const copyAndAlert = (account) => {
    copyToClipboard(account)
    addAlert({ variables: { message: "Conta copiada!", type: 'SUCCESS_ALERT' } })
  }

  const onlyDiffsHandler = e => {
    setOnlyDiffs(e.target.checked)
  }
  
  /* eslint eqeqeq: 0 */
  const comparationFiltered = !onlyDiffs ? comparation : comparation.filter((val) => !((val.reference - val.former).toFixed(2) == 0))

  if (error) return <div>Erro :(</div>

  return (
    <>
      <div className="account-comparation__definition">Unidade executora: { unit }</div>
      <div className="account-comparation__definition">Conta contábil: { account }</div>
      { (loading || networkStatus === 4) && <div>Carregando...</div> }
      
      <p>
        <input type="checkbox" name="showOnlyDiffs" checked={onlyDiffs} onChange={onlyDiffsHandler} />
        <label htmlFor="showOnlyDiffs">Mostrar apenas diferenças</label>
      </p>
      

      <button className="btn" onClick={() => refetch()}><MdRefresh /></button>

      <ul className="comparation-list">
        <li key="a" className="comparation-list__item comparation-list__item--header">
            <div className="comparation-list__first-element">Valor anterior</div>
            <div className="comparation-list__second-element">Valor de atualização</div>
        </li>
        { !loading && comparationFiltered.map(({ account, former, reference}, idx) => (
            <li key={idx} className="comparation-list__item">
              <div className="comparation-list__header">{upperFirst(toLower(elementDic(account)))}</div>
              <div className="comparation-list__subheader" onClick={() => copyAndAlert(account) }>
                <MdContentCopy />  
                <div>{format(account)}</div>
              </div>
              <div className="comparation-list__first-element">{ currencyFormat(former) }</div>
              <div className="comparation-list__second-element">{ currencyFormat(reference) }</div>
              <div className="comparation-list__difference-element">{ currencyFormat(subtract(reference, former)) }</div>
            </li>
        )) }
      </ul>
    </>
  )
}

export default AccountComparation