import { gql } from 'apollo-boost'

export const SAVE_ACCOUNT_EVENT_DESCRIPTION = gql`
  mutation savaAccountEventDescription($id: ID!, $description: String!, $sectionId: ID!) {
    saveAccountEventDescription(id: $id, description: $description, sectionId: $sectionId) {
      id
      value
      documentNumber
      registryDate
      unitOrigin
      description
      type
      assignedTo {
        id
        name
      }
    }
  }
`

export const ACCOUNT_EVENT = gql`
  query AccountEvent ($id: ID!) {
    accountEvent(id: $id) {
      id
      value
      documentNumber
      registryDate
      unitOrigin
      description
      type
      assignedTo {
        id
        name
      }
    }
    allSections {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const ADD_ALERT = gql`
  mutation AddAlert($type: AlertClientEnum, $message: String){
    addAlert(type: $type, message: $message) @client
  }
`

export const ACCOUNT_EVENT_DESCRIPTION_MODAL = gql`
  query AccountEventDescriptionModal {
    accountEventDescriptionModal @client {
      isHidden
      eventId
    }
  }
`

export const HIDE_ACCOUNT_EVENT_DESCRIPTION_MODAL = gql`
  mutation HideAccountEventDescriptionModal {
    hideAccountEventDescriptionModal @client
  }
`