import React from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

const MainContaintLoading = ({ size = "big" }) => {
  let cls = 'main-containt-loading'
  
  switch (size) {
    case 'tiny':
      cls = [cls, 'main-containt-loading--tiny'].join(' ')
      break;
  
    default:
      break;
  }
  return (
    <div className={cls}>
      <AiOutlineLoading3Quarters />
    </div>
  )
}

export default MainContaintLoading
