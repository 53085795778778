import React from 'react'
import { Helmet } from 'react-helmet-async'
import { 
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import AccountsUpdate from './pages/AccountsUpdate'
import AccountsList from './pages/AccountsList'
import AuxiliariesAccountList from './pages/AuxiliariesAccountList'
import AccountEvents from './pages/AccountEvents'
import Profile from './pages/Profile'
import Section from './pages/Section'
import Acquisition from './pages/Acquisition'
import Reports from './pages/Reports'
import CreditReport from './pages/CreditReport'
import UnityReport from './pages/UnityReport'

import AlertNotification from './components/AlertNotification'
import ToTopBtn from './components/ToTopBtn'
import PrivateRoute from './components/PrivateRoute'
import SideNav from './components/SideNav'

import EventDescriptionModal from './modals/EventDescriptionModal'
import AcquisitionFormModal from './modals/AcquisitionFormModal'
import AccountEventModal from './modals/AccountEventModal'
import TaskFormModal from './modals/TaskFormModal'
import GanttModal from './modals/GanttModal'

const App = () => {
  
  return (
    <Router>
      <Helmet>
        <title>CBMMG - CSM Repasses</title>
        <meta name="google-signin-client_id" content="46978641145-6d2qg82ltnel2tth2f0qog83e6je3voe.apps.googleusercontent.com"></meta>
      </Helmet>

      <AlertNotification />
      
      <Switch>
        <Route path="/login" component={Login} />
        
        <Route path="/">
          <div className="container-grid">

            <SideNav />
            <ToTopBtn />
            
            <EventDescriptionModal />
            <AcquisitionFormModal />
            <AccountEventModal />
            <TaskFormModal />
            <GanttModal />

            <Switch>

              <PrivateRoute path="/account-update" component={AccountsUpdate} />
              <PrivateRoute path="/accounts-list/:unit/:accountNumber" component={AuxiliariesAccountList} />
              <PrivateRoute path="/accounts-list" component={AccountsList} />
              <PrivateRoute path="/account-events/:accountId" component={AccountEvents} />
              <PrivateRoute path="/profile" component={Profile} />
              <PrivateRoute path="/sections/:sectionId" component={Section} />
              <PrivateRoute path="/acquisition/:acquisitionId" component={Acquisition} />
              <PrivateRoute path="/reports/credits" component={CreditReport} />
              <PrivateRoute path="/reports/unities" component={UnityReport} />
              <PrivateRoute path="/reports" component={Reports} />
              <PrivateRoute path="/" component={Dashboard} />

            </Switch>
          </div>
        </Route>
      </Switch>
    </Router>
  )
}

export default App;
