import { getTokens } from '../helpers'

const initialState = {
  data: {
    isLoggedIn: (getTokens().accessToken || getTokens().refreshToken) !== '',
    alerts: [],
    acquisitionFormModal: {
      isHidden: true,
      sectionId: null,
      acquisitionId: null,
      isCreating: null,
      __typename: 'AcquisitionFormModalClient'
    },
    accountEventModal: {
      isHidden: true,
      eventId: null,
      __typename: 'AccountEventModalClient'
    },
    taskFormModal: {
      isHidden: true,
      taskId: null,
      isCreating: null,
      acquisitionId: null,
      __typename: 'TaskFormModalClient'
    },
    accountEventDescriptionModal: {
      isHidden: true,
      eventId: null,
      __typename: 'AccountEventDescriptionModalClient'
    },
    ganttModal: {
      isHidden: true,
      tasks: null,
      __typename: 'GanttModal'
    }
  }
}

export default initialState