import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { MdAddToPhotos, MdDeleteForever } from 'react-icons/md'
import _ from 'lodash'

import { ALL_UNITIES, ASSOCIATE_UNITY, DISASSOCIATE_UNITY } from '../gqls'
import Loading from '../../../components/Loading'

const onError = error => console.log(error)

const BenefitedUnities = ({ benefitedUnities, acquisitionId }) => {
  const unitiesQuery = useQuery(ALL_UNITIES)
  const [associateUnity, associateUnityMutation] = useMutation(ASSOCIATE_UNITY, {
    onError,
    onCompleted: data => {
      console.log(data)
    }
  })
  const [disassociateUnity, disassociateUnityMutation] = useMutation(DISASSOCIATE_UNITY, {
    onError,
    onCompleted: data => {
      console.log(data)
    }
  })

  const [unity, setUnity] = useState('')
  const availebleUnities = _.get(unitiesQuery, ['data', 'allUnities', 'edges'], [])
  const loading = associateUnityMutation.loading || disassociateUnityMutation.loading || unitiesQuery.loading

  const addUnityHandler = () => {
    associateUnity({
      variables: {
        acquisitionId,
        unityId: unity
      }
    })
  }

  const deleteUnityHandler = unityId => {
    disassociateUnity({ variables: { acquisitionId, unityId } })
  }

  return (
    <div className="simple-card simple-card--fit-content">
      <div className="simple-card__featured">Unidades beneficiadas</div>
      <div className={`hidden ${loading ? 'show' : ''}`}>
        <Loading />
      </div>
      <div className="simple-card__description">
        <div className="form-field form-field--inline-btn">
          <select disabled={loading} name="unite" className="form-field__select" required value={unity} onChange={e => setUnity(e.target.value)}>
            <option value=""></option>
            {availebleUnities.map(({ node }, idx) => (
              <option key={idx} value={node.id}>{node.name}</option>
            ))}
          </select>
          <label htmlFor="unite" className="form-field__label">Unidade</label>
          <button disabled={unity === '' || loading} className="btn-icon" onClick={addUnityHandler}><MdAddToPhotos /></button>
        </div>
      </div>
      <div className="simple-card__description scroll-list-wrapper">
        <ul className="credit-events-list">
          {benefitedUnities.map(({ node }) => (
            <div className="credit-events-list__item" key={node.id}>
              <div className="credit-events-list__doc">{node.name}</div>
              <div className="credit-events-list__date">{node.city}</div>
              <div className="credit-events-list__actions">
                <button disabled={loading} className="btn-icon" onClick={() => deleteUnityHandler(node.id)}><MdDeleteForever /></button>
              </div>
            </div>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default BenefitedUnities