import React, {Fragment} from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import { FiEdit } from 'react-icons/fi'
import { AiFillDiff } from 'react-icons/ai'

import MainContaintLoading from '../../components/MainContaintLoading'
import { ALL_ACCOUNT_EVENTS, SHOW_ACCOUNT_EVENT_DESCRIPTION_MODAL, SHOW_ACCOUNT_EVENT_MODAL } from './gqls'
import { currencyFormat } from '../../helpers'
import { useScrollInfo } from '../../hooks'

const CreditReport = () => {
  const allAccountEventsQuery = useQuery(ALL_ACCOUNT_EVENTS)
  
  return (
    <>
      <header className="header"></header>
      <main className="main">
        <ComponentRenderer {...allAccountEventsQuery } />
      </main>
      <footer className="footer"></footer>
    </>
  )
}

const updateQuery = (previousEntry, { fetchMoreResult }) => ({
  allAccountEvents: {
    ...fetchMoreResult.allAccountEvents,
    edges: previousEntry.allAccountEvents.edges.concat(fetchMoreResult.allAccountEvents.edges)
  }
})

const ComponentRenderer = ({ data, loading, fetchMore }) => {
  const [showModal] = useMutation(SHOW_ACCOUNT_EVENT_MODAL)
  const [showDescriptionModal] = useMutation(SHOW_ACCOUNT_EVENT_DESCRIPTION_MODAL)

  const listWrapperRef = useScrollInfo({
    effect: ({ currPos }) => {
      if (currPos.x.isAtTheEnd && data.allAccountEvents.pageInfo.hasNextPage) {
        fetchMore({
          query: ALL_ACCOUNT_EVENTS,
          variables: { after: data.allAccountEvents.pageInfo.endCursor },
          updateQuery
        })
      }
    },
    wait: 300
  })

  if (loading) return <MainContaintLoading />

  const rowSpan = array => (array.length > 1) ? {rowSpan:array.length} : {}
  const showModalHandler = eventId => showModal({ variables: { eventId } })
  const showDescriptionModalHandler = eventId => showDescriptionModal({ variables: { eventId } })

  return (
    <div className="auto-fit-grid">
      <div className="simple-card">
        <div className="simple-card__description">
          <div ref={listWrapperRef}  className="table-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th rowSpan="2">Ações</th>
                  <th rowSpan="2">Documento</th>
                  <th rowSpan="2">ED</th>
                  <th rowSpan="2">Atribuido</th>
                  <th rowSpan="2">Data</th>
                  <th rowSpan="2">Tempo</th>
                  <th rowSpan="2">Valor</th>
                  <th rowSpan="2">Pendente</th>
                  <th colSpan="3">Associações</th>
                </tr>
                <tr>
                  <th>Objeto</th>
                  <th>Valor</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                { data && data.allAccountEvents.edges.map(({node}, idx) => (
                  <Fragment key={node.id}>
                    <tr className={idx%2 === 0? 'even':'odd' }>
                      <td {...rowSpan(node.associations)} >
                        <button className="btn-icon" onClick={() => showDescriptionModalHandler(node.id)}><FiEdit /></button>
                        <button className="btn-icon" onClick={() => showModalHandler(node.id)}><AiFillDiff /></button>
                      </td>
                      <td {...rowSpan(node.associations)} className="center"><Link className="link" to={`/account-events/${node.account.id}`}>{node.documentNumber} - {node.year}</Link></td>
                      <td {...rowSpan(node.associations)} >{getED(node.account.detailing)}</td>
                      <td {...rowSpan(node.associations)} >{_.get(node, ['assignedTo', 'name'], '')}</td>
                      <td {...rowSpan(node.associations)} > {moment(node.registryDate).format('DD/MM/YYYY')}</td>
                      <td {...rowSpan(node.associations)} > {moment(node.registryDate).fromNow()}</td>
                      <td {...rowSpan(node.associations)} > {currencyFormat(node.value)}</td>
                      <td {...rowSpan(node.associations)} className={node.balance > 0 ? 'text-warn': ''}> {currencyFormat(node.balance)}</td>
                      <td>
                        { (_.get(node, ['associations', '0'], false)) && <Link className="link" to={`/acquisition/${_.get(node, ['associations','0','acquisition','id'])}`}>{_.get(node, ['associations', '0', 'acquisition', 'name'])}</Link>}
                      </td>
                      <td>
                        {currencyFormat(_.get(node, ['associations', '0', 'value'])) }
                      </td>
                      <td>
                        {_.get(node, ['associations', '0', 'acquisition', 'tasks', 'edges', '0', 'node', 'name'])}
                      </td>
                    </tr>
                    { (node.associations.length > 1) && node.associations.slice(1).map((association, innerIdx) => (
                      <tr key={node.id + '' + innerIdx} className={idx % 2 === 0 ? 'even' : 'odd'}>
                        <td><Link className="link" to={`/acquisition/${association.acquisition.id}`}>{association.acquisition.name}</Link></td>
                        <td>{currencyFormat(association.value)}</td>
                        <td>{_.get(association, ['acquisition', 'tasks', 'edges', '0', 'node', 'name'])}</td>
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

const getED = account => {
  if (account.length === 31) {
    const key = account.slice(25, 27)
    return key
  }

  if (account.length === 33) {
    const key = account.slice(25, 29)
    return key
  }

  return ''
}

export default CreditReport