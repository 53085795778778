import React, { Fragment } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment' // important
import MainContaintLoading from '../../components/MainContaintLoading'
import { ACQUISITIONS_PER_UNITY } from './gqls'
import { currencyFormat } from '../../helpers'

const UnityReport = () => {
  const acquisitionsPerUnityQuery = useQuery(ACQUISITIONS_PER_UNITY)

  return (
    <>
      <header className="header"></header>
      <main className="main">
        <ComponentRenderer {...acquisitionsPerUnityQuery} />
      </main>
      <footer className="footer"></footer>
    </>
  )
}

const ComponentRenderer = ({ data, loading }) => {

  if (loading) return <MainContaintLoading />

  const csvHandler = () => {
    const sep = ','
    const header = `"Unidade", "Cidade", "Nome", "Início", "Final", "Status", "Valor"\n`

    const csvString = data.acquisitionsPerUnity.map((acquisition) => {
      const lineString = '' +
        `"${acquisition.unity.name}"${sep}` +
        `"${acquisition.unity.city}"${sep}` +
        `"${acquisition.name}"${sep}` +
        `"${_.get(acquisition, ['tasks', 'edges', '0', 'node', 'name'])}"${sep}` +
        `"${currencyFormat(acquisition.associatedAccountEvents.reduce((acc, { value }) => acc + value, 0))}"`
        // `"${moment(acquisition.planStartDate).format('DD/MM/YYYY')}"${sep}` +
        // `"${moment(acquisition.planEndDate).format('DD/MM/YYYY')}"${sep}` +
        
        return lineString
      }).join('\n')

    const csvContent = "data:text/csv;charset=utf-8," + header + csvString
    const encodedUri = encodeURI(csvContent)
    
    var link = document.createElement("a")
    link.setAttribute("href", encodedUri)
    link.setAttribute("download", "procedimentos.csv")
    document.body.appendChild(link)

    link.click()
    link.parentNode.removeChild(link)
  }
  
  return (
    <>
      <div className="auto-fill-grid"><button className="btn" onClick={csvHandler}>CSV</button></div>
      <div className="auto-fit-grid">
        <div className="simple-card">
          <div className="simple-card__description">
            <div className="table-wrapper">
              <table className="table">
                <thead>
                  <tr>
                    <th>Unidade</th>
                    <th>Cidade</th>
                    <th>Nome</th>
                    {/* <th>Início</th>
                    <th>Final</th> */}
                    <th>Status</th>
                    <th>Valor</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.acquisitionsPerUnity.map((acquisition, idx) => (
                    <Fragment key={idx}>
                      <tr className={idx % 2 === 0 ? 'even' : 'odd'}>
                        <td>{acquisition.unity.name}</td>
                        <td>{acquisition.unity.city}</td>
                        <td><Link className="link" to={`/acquisition/${acquisition.acquisitionId}`}>{acquisition.name}</Link></td>
                        {/* <td>{moment(acquisition.planStartDate).format('DD/MM/YYYY')}</td>
                        <td>{moment(acquisition.planEndDate).format('DD/MM/YYYY')}</td> */}
                        <td>{_.get(acquisition, ['tasks', 'edges', '0', 'node', 'name'])}</td>
                        <td>{currencyFormat(acquisition.associatedAccountEvents.reduce((acc, {value}) => acc + value,0))}</td>
                      </tr>
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UnityReport