import React, { Fragment } from 'react'
import moment from 'moment'

import { options } from '../helpers'

const DateLayer = ({ countDates, minDate }) => {
  const dates = Array.from(Array(countDates), (val, idx) => moment(minDate).add(idx, 'days').toISOString())

  return (
    <g className="date"> {/* date layer */}
      {dates.map((date, idx) => (
        <Fragment key={idx}>
          <LowerText idx={idx} day={moment(date).format('D')} />
          {(moment(date).format('D') === '15') && (
            <UpperText idx={idx} month={moment(date).format('MMMM')} />
          )}
        </Fragment>
      ))}
    </g>
  )
}

export default DateLayer

const UpperText = ({ idx, month }) => {
  const { columnWidth, headerHeight } = options

  return (
    <text
      x={idx * columnWidth + columnWidth / 2}
      y={headerHeight - 25}
      className='upper-text'>
      {month}
    </text>
  )
}

const LowerText = ({ idx, day }) => {
  const { columnWidth, headerHeight } = options

  return (
    <text
      x={idx * columnWidth + columnWidth / 2}
      y={headerHeight}
      className='lower-text'>
      {day}
    </text>
  )
}