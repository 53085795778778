import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { FiEye } from 'react-icons/fi'
import { MdRefresh } from 'react-icons/md'
import { capitalize } from 'lodash'

import { currencyFormat } from '../../helpers'
import { format as dicFormat } from '../../helpers/accountDictionary'
import { useScrollInfo } from '../../hooks'

const Dashboard = () => {
  return (
    <>
      <header className="header">Dashboard</header>
      <main className="main">
        <div className="auto-fit-grid">
          <LastCreditAccountEvents />
          <LastAccountEvents />
        </div>
      </main>
      <footer className="footer"></footer>
    </>
  )
}

const updateQuery = (previousEntry, { fetchMoreResult }) => ({
  allAccountEvents: {
    ...fetchMoreResult.allAccountEvents,
    edges: previousEntry.allAccountEvents.edges.concat(fetchMoreResult.allAccountEvents.edges)
  }
})

const LastCreditAccountEvents = () => {
  const { data, loading, fetchMore } = useQuery(LAST_CREDIT_ACCOUNT_EVENTS, {
    notifyOnNetworkStatusChange: true
  })
  
  const listWrapperRef = useScrollInfo({
    effect: ({ currPos }) => {
      if (currPos.x.isAtTheEnd && data.allAccountEvents.pageInfo.hasNextPage) {
        fetchMore({
          query: LAST_CREDIT_ACCOUNT_EVENTS,
          variables: { after: data.allAccountEvents.pageInfo.endCursor },
          updateQuery
        })
      }
    },
    wait: 300
  })

  return (
    <div className="simple-card">
      <div className="simple-card__featured">Ultimos repasses</div>
      <div ref={listWrapperRef} className="simple-card__description scroll-list-wrapper">
      { data && 
        <ul className="credit-events-list">
          { data.allAccountEvents.edges.map(({ node }, idx) => (
            <li key={idx} className="credit-events-list__item">
              <div className="credit-events-list__doc">
                Doc. {node.unitOrigin} / {node.documentNumber}
              </div>
              <div className="credit-events-list__date">
                {moment(node.registryDate).format('D [de] MMMM YYYY')}
              </div>
              <div className="credit-events-list__value">
                {currencyFormat(node.value)}
                <span className="credit-events-list__detailing">{dicFormat(node.account.detailing).slice(27)}</span>
              </div>
              <div className="credit-events-list__actions">
                <Link to={`/account-events/${node.account.id}`} className="btn-icon"><FiEye /></Link>
              </div>
            </li>
          ))}
        </ul> 
      }
      { loading && 
        <div className="scroll-list-wrapper__loading">
          <MdRefresh className="scroll-list-wrapper__loading-spin" />
        </div> 
      }
      </div>
    </div>
  )
}


const LastAccountEvents = () => {
  const { data, loading, fetchMore } = useQuery(LAST_ACCOUNT_EVENTS, {
    notifyOnNetworkStatusChange: true
  })
  
  const listWrapperRef = useScrollInfo({
    effect: ({ currPos }) => {
      if (currPos.x.isAtTheEnd && data.allAccountEvents.pageInfo.hasNextPage) {
        fetchMore({
          query: LAST_ACCOUNT_EVENTS,
          variables: { after: data.allAccountEvents.pageInfo.endCursor },
          updateQuery
        })
      }
    },
    wait: 300
  })

  return (
    <div className="simple-card simple-card--secondary">
      <div className="simple-card__featured">Ultimos eventos</div>
      <div ref={listWrapperRef} className="simple-card__description scroll-list-wrapper">
      { data && 
        <ul className="credit-events-list">
          { data.allAccountEvents.edges.map(({ node }, idx) => (
            <li key={idx} className="credit-events-list__item">
              <div className="credit-events-list__doc">
                Doc. {node.unitOrigin} / {node.documentNumber} - {capitalize(node.type)}
              </div>
              <div className="credit-events-list__date">
                {moment(node.registryDate).format('D [de] MMMM YYYY')}
              </div>
              <div className="credit-events-list__value">
                {currencyFormat(node.value)}
                <span className="credit-events-list__detailing">{dicFormat(node.account.detailing).slice(27)}</span>
              </div>
              <div className="credit-events-list__actions">
                <Link to={`/account-events/${node.account.id}`} className="btn-icon"><FiEye /></Link>
              </div>
            </li>
          ))}
        </ul> 
      }
      { loading && 
        <div className="scroll-list-wrapper__loading">
          <MdRefresh className="scroll-list-wrapper__loading-spin" />
        </div> 
      }
      </div>
    </div>
  )
}

export default Dashboard

const LAST_CREDIT_ACCOUNT_EVENTS = gql`
  query lastAccountEvents ($after: Cursor) {
    allAccountEvents(
      first: 10, 
      after: $after, 
      order: REGISTRY_DATE__DESC
      filter: { type: "REPASSE DE COTA ORCAMENTARIA DESCENTRALIZADA" }
    ) {
      edges {
        node {
          id
          value
          documentNumber
          registryDate
          unitOrigin
          account {
            id
            detailing
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

const LAST_ACCOUNT_EVENTS = gql`
  query lastAccountEvents ($after: Cursor) {
    allAccountEvents(
      first: 10, 
      after: $after, 
      order: REGISTRY_DATE__DESC
    ) {
      edges {
        node {
          id
          value
          documentNumber
          registryDate
          unitOrigin
          type
          account {
            id
            detailing
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`