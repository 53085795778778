import { gql } from 'apollo-boost'

export const ACQUISITION = gql`
  query Acquisition($id: ID!) {
    acquisition(id: $id) {
      id
      type
      name
      description
      planStartDate
      planEndDate
    }
  }
`

export const SAVE_ACQUISITION = gql`
  mutation SaveAcquisition(
    $planStartDate: Date!
    $planEndDate: Date!
    $description: String
    $type: AcquisitionType
    $name: String!
    $sectionId: ID!
  ) {
    saveAcquisition(input: {
      planStartDate: $planStartDate
      planEndDate: $planEndDate
      description: $description
      type: $type
      name: $name
      sectionId: $sectionId
    }) {
      id
      type
      name
      description
      planStartDate
      planEndDate
      startDate
      endDate
      tasks {
        edges {
          node {
            isCompleted
          }
        }
      }
    }
  }
`
export const UPDATE_ACQUISITION = gql`
  mutation UpdateAcquisition(
    $planStartDate: Date!
    $planEndDate: Date!
    $description: String
    $name: String!
    $id: ID!
  ) {
    updateAcquisition(input: {
      planStartDate: $planStartDate
      planEndDate: $planEndDate
      description: $description
      name: $name
      id: $id
    }) {
      id
      type
      name
      description
      planStartDate
      planEndDate
    }
  }
`

export const ACQUISITION_FORM_MODAL = gql`
  query AcquisitionFormModal {
    acquisitionFormModal @client {
      isHidden
      sectionId
      acquisitionId
      isCreating
    }
  }
`

export const HIDE_ACQUISITION_FORM_MODAL = gql`
  mutation HideAcquisitionFormModal {
    hideAcquisitionFormModal @client
  }
`