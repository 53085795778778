import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import 'moment/locale/pt-br'
import { HelmetProvider } from 'react-helmet-async'

import App from './App'
import * as serviceWorker from './serviceWorker'
import apolloClient from './apollo/client'

import './index.scss'

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
