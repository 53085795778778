import _ from 'lodash'
import moment from 'moment'

import { colors, currencyFormat } from '../../helpers'

const timeChartConfig = ({ data }) => ({
  type: 'line',
  data: {
    datasets: [{
      data: data,
      lineTension: 0,
      borderColor: colors[0]
    }]
  },
  options: {
    title: {
      display: true,
      text: "Valor acumulado por data"
    },
    tooltips: {
      callbacks: {
        title: titleDate => titleDate.map(title => moment(title.label).format("DD/MM/YYYY h:mm:ss a")),
        label: tooltipItem => currencyFormat(tooltipItem.value)
      }
    },
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          callback: (value) => currencyFormat(value)
        }
      }],
      xAxes: [{
        type: 'time',
        time: {
          unit: 'month'
        },
        ticks: {
          min: new Date(`${new Date().getFullYear()}-01-01`)
        }
      }]
    }
  }
})

const typeChartConfig = ({ data }) => ({
  type: 'bar',
  data: {
    labels: data.labels,
    datasets: [{
      data: data.values,
      backgroundColor: colors,
    }]
  },
  options: {
    title: {
      display: true,
      text: "Valor acumulado por tipo de documento"
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem) => currencyFormat(tooltipItem.value)
      }
    },
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        ticks: {
          callback: (value) => _.truncate(_.capitalize(value), { length: 15 }),
          autoSkip: true,
        }
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true,
          callback: (value) => currencyFormat(value)
        },
      }]
    }
  }
})

export {
  timeChartConfig,
  typeChartConfig
}