import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'

const GET_ALERTS = gql`
  query GetAlerts {
    alerts @client {
      type
      message
      id
    }
  }
`

const AlertNotificaiton = () => {
  const { data: { alerts } } = useQuery(GET_ALERTS)

  return (
    <div className="snackbar-wrapper">
      { alerts.map(({ message, id }) => (
        <div className={ 'snackbar ' + (message ? 'show':'') } key={id}>
          { message }
        </div>
      ))}
    </div>
  )
}

export default AlertNotificaiton
