import React from 'react'

const Profile = () => {
  return (
    <>
      <header className="header">Perfil</header>
      <main className="main">
        <div className="auto-fit-grid">
          <PasswordUpdater />
          <UserDataUpdater />
        </div>
      </main>
      <footer className="footer"></footer>
    </>
  )
}

const PasswordUpdater = () => {
  return (
    <div className="simple-card simple-card--no-stretch">
      <div className="simple-card__featured">Alterar senha</div>
      <div className="form-field">
        <label className="form-field__label" htmlFor="password">Senha</label>
        <input className="form-field__input" type="text" name="password" placeholder="Senha" />
      </div>
      <div className="form-field">
        <label className="form-field__label" htmlFor="confirmPassword">Confirme a senha</label>
        <input className="form-field__input" type="text" name="confirmPassword" placeholder="Confirme a senha" />
      </div>
      <div className="form-actions">
        <button className="btn">Salvar</button>
      </div>
    </div>
  )
}

const UserDataUpdater = () => {
  return (
    <div className="simple-card simple-card--fit-content">
      <div className="simple-card__featured">Alterar dados</div>
      <div className="form-field">
        <label className="form-field__label" htmlFor="nome">Nome</label>
        <input className="form-field__input" type="text" name="nome" placeholder="Nome" />
      </div>
      <div className="form-actions">
        <button className="btn">Salvar</button>
      </div>
    </div>
  )
}

export default Profile