import React, { useRef, useEffect, useState } from 'react'

import { options, barX, barY, barWidth } from '../helpers'

const BarLayer = ({ tasks, minDate }) => {
  return (
    <g className="bar"> {/* bar layer */}
      {tasks.map((task, idx) => (
        <g className="bar-wrapper" data-id={task.id} key={idx}>
          <BarGroup task={task} idx={idx} minDate={minDate} />
        </g>
      ))}
    </g>

  )
}

export default BarLayer

const BarGroup = ({ task, minDate, idx }) => {
  const labelRef = useRef('')
  const [bigLabel, setBigLabel] = useState(false)

  const { barHeight } = options

  const x = barX(task, minDate)
  const y = barY(idx)

  let progress = parseInt(task.progress, 10)
  progress = typeof progress === 'number' ? progress : 0

  const width = barWidth(task)
  const progressWidth = width * (progress / 100)

  const labelX = bigLabel ? (x + width + 5) : (x + width / 2)

  useEffect(() => {
    const labelWidth = labelRef.current.getBBox().width
    setBigLabel(labelWidth > width)
  }, [labelRef, width])

  return (
    <g className="bar-group">
      <rect x={x} y={y} width={width} height={barHeight} rx='3' ry='3' className="bar" />
      <rect x={x} y={y} width={progressWidth} height={barHeight} rx='3' ry='3' className="bar-progress" />
      <text x={labelX} y={y + barHeight / 2} ref={labelRef} className={`bar-label ${bigLabel?'big':''}`} >{task.name}</text>
    </g>
  )
}