import { gql } from 'apollo-boost'

export const SHOW_ACCOUNT_EVENT_MODAL = gql`
  mutation ShowAccountEventModal($eventId: ID!) {
    showAccountEventModal(eventId: $eventId) @client
  }
`

export const SHOW_ACCOUNT_EVENT_DESCRIPTION_MODAL = gql`
  mutation ShowAccountEventDescriptionModal($eventId: ID!) {
    showAccountEventDescriptionModal(eventId: $eventId) @client
  }
`

export const ALL_ACCOUNT_EVENTS = gql`
  query AllAccountEvents($after: Cursor) {
    allAccountEvents(
      first: 20
      after: $after
      order: REGISTRY_DATE__DESC
      filter: {
        type: "REPASSE DE COTA ORCAMENTARIA DESCENTRALIZADA"
      }
    ) {
      edges {
        node {
          id
          account {
            id
            detailing
          }
          value
          balance
          documentNumber
          year
          registryDate
          assignedTo {
            name
          }
          associations {
            id
            value
            description
            acquisition {
              id
              name
              tasks (
                first: 1
                filter: {
                  isCompleted: false
                }
                order: PLAN_START_DATE__ASC
              ) {
                edges {
                  node {
                    name
                  }
                }
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

