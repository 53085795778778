import React, { useEffect, useRef, useState } from 'react'
import Chartjs from 'chart.js'
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from 'react-icons/ai'

function Chart({ options, className, style, ...args }) {
  const [ isFullsize, setIsFullsize ] = useState(false)
  const chartRef = useRef(null)
  
  useEffect(() => {
    new Chartjs(chartRef.current, options)
  }, [options])

  const cls = isFullsize ? 
    'chart-wrapper chart--fullsize ' + (className || '') :
    'chart-wrapper ' + (className || '')

  const newStyle = isFullsize ? {} : style
  
  return (
    <div className={cls} { ...args }>
      {!isFullsize && <button className="btn-icon btn--chart-fullsize" onClick={() => setIsFullsize(true)}><AiOutlineFullscreen /></button> }
      {isFullsize && <button className="btn-icon btn--chart-fullsize" onClick={() => setIsFullsize(false)}><AiOutlineFullscreenExit /></button>}
      <div className="chart-container" style={newStyle} >
        <canvas ref={chartRef} className="chart"></canvas>
      </div>
    </div>
  )
}
export default React.memo(Chart)
