import React from 'react'

import { options, barY, barWidth, barX } from '../helpers'

const ArrowLayer = ({ tasks, minDate }) => {
  const tasksMap = tasks.reduce((acc, task, idx) => ({ ...acc, [task.id]: { task, idx } }), {})

  const dependencies = tasks.reduce((acc, task) => {
    if (typeof task.dependencies !== 'string') return acc
    const depsIdsFromString = task.dependencies.split(',').filter(val => val !== '').map(val => val.replace(/\s+/g, ''))
    const links = depsIdsFromString.map(dep => ({ from: tasksMap[dep], to: tasksMap[task.id] }))
    
    return [...acc, ...links]
  }, [])

  return (
    <g className="arrow"> {/* arrow  layer*/}
      {dependencies.map((dependencie, idx) => (
        <Arrow dependencie={dependencie} minDate={minDate} key={idx} />
      ))}
    </g>
  )
}

export default ArrowLayer

const Arrow = ({ dependencie, minDate }) => {
  const { from, to } = dependencie

  const startX = barX(from.task, minDate) + barWidth(from.task) / 2 - 10
  const startY = barY(from.idx) + options.barHeight
  const endX = barX(to.task, minDate) - options.padding / 2
  const endY = barY(to.idx) + options.barHeight / 2

  const fromIsBelowTo = from.idx > to.idx
  const curve = 5
  const clockwise = fromIsBelowTo ? 1 : 0
  const curveY = fromIsBelowTo ? -curve : curve
  const offset = fromIsBelowTo ? (endY + curve) : (endY - curve)

  const path = `
    M ${startX} ${startY}
    V ${offset}
    a ${curve} ${curve} 0 0 ${clockwise} ${curve} ${curveY}
    L ${endX} ${endY}
    m -5 -5
    l 5 5
    l -5 5
  `

  return <path d={path} data-from={from.task.id} data-to={to.task.id} />
}