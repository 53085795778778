import { useState } from 'react'
import { fetch as wgFetch } from 'whatwg-fetch'

import config from '../config'

const fetchXlsToJson = formData => {
  return wgFetch(config.xls2json.URL, {
    method: 'POST',
    body: formData,
  }).then(response => {
    return response.json()
  })
}

const useXlsToJson = (dataHandler) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  const fetch = async file => {
    try {
      if (!file) throw new Error('File should not be empty!')

      setLoading(true)
    
      let formData = new FormData()
      formData.append('sheet', file)
    
      const json = await fetchXlsToJson(formData)
      const newData = dataHandler(json)
      setData(newData)
      setLoading(false)
      setError(false)
    }
    catch(err) {
      setLoading(false)
      setData(null)
      setError(err)
    }
  }

  return [ fetch, { loading, data, error }]
}

export default useXlsToJson