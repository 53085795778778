import React from 'react'
import { useParams} from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import moment from 'moment'
import _ from 'lodash'
import { FaCalendarCheck, FaCalendarAlt, FaBalanceScale } from 'react-icons/fa'
import { MdAttachMoney, MdMoneyOff} from 'react-icons/md'

import AssociatedEventsList from './AssociatedEventsList'
import TasksList from './TasksList'
import BenefitedUnities from './BenefitedUnities'
import MainContaintLoading from '../../components/MainContaintLoading'
import { ACQUISITION } from './gqls'
import { currencyFormat} from '../../helpers'

const onError = error => console.log(error)

const Acquisition = () => {
  const { acquisitionId } = useParams()
  const { data, loading } = useQuery(ACQUISITION, { 
    onError, 
    variables: {
      id: acquisitionId
    } 
  })

  const sectionName = _.get(data, ['acquisition', 'section', 'name'], '')
  const name = _.get(data, ['acquisition', 'name'], '')
  
  return (
    <>
      <header className="header">
        { sectionName? `Seção > ${sectionName} > ${name}`: '' }
      </header>
      <main className="main">
        <ComponentRenderer data={data} loading={loading} />
      </main>
      <footer className="footer"></footer>
    </>
  )
}

const ComponentRenderer = ({ data, loading }) => {
  const associatedAccountEvents = _.get(data, ['acquisition', 'associatedAccountEvents'], [])
  const tasks = _.get(data, ['acquisition', 'tasks', 'edges'], [])
  const totalIn = _.get(data, ['acquisition', 'totalIn'], 0)
  const totalOut = _.get(data, ['acquisition', 'totalOut'], 0)
  const balance = (totalIn + totalOut)
  const planStartDate = _.get(data, ['acquisition', 'planStartDate'], (new Date().toISOString()))
  const planEndDate = _.get(data, ['acquisition', 'planEndDate'], (new Date().toISOString()))
  const benefitedUnities = _.get(data, ['acquisition', 'benefitedUnities', 'edges'], [])
  const acquisitionId = _.get(data, ['acquisition', 'id'])

  if (loading) return <MainContaintLoading />
  
  return (
    <>
      <div className="auto-fit-grid auto-fit-grid--large">
        <div className="auto-fill-grid">
          <div className="info-card">
            <div className="info-card__icon"><FaCalendarAlt /></div>
            <div className="info-card__info">{moment(planStartDate).format('DD/MM/YYYY')}</div>
          </div>
          <div className="info-card">
            <div className="info-card__icon"><FaCalendarCheck /></div>
            <div className="info-card__info">{moment(planEndDate).format('DD/MM/YYYY')}</div>
          </div>
          <div className="info-card">
            <div className="info-card__icon"><MdAttachMoney /></div>
            <div className="info-card__info">{currencyFormat(totalIn)}</div>
          </div>
          <div className="info-card">
            <div className="info-card__icon"><MdMoneyOff /></div>
            <div className="info-card__info">{currencyFormat(totalOut)}</div>
          </div>
          <div className="info-card">
            <div className="info-card__icon"><FaBalanceScale /></div>
            <div className="info-card__info" >{currencyFormat(balance)}</div>
          </div>
        </div>
      </div>
      <div className="auto-fill-grid">
      </div>
      <div className="auto-fit-grid" >
        <TasksList data={tasks} acquisitionId={acquisitionId} />
        <AssociatedEventsList events={associatedAccountEvents} />
        <BenefitedUnities benefitedUnities={benefitedUnities} acquisitionId={acquisitionId} />
      </div>
    </>
  )
}

export default Acquisition