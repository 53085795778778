import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { MdList } from 'react-icons/md'

import { currencyFormat } from '../../../helpers'
import { format as dicFormat } from '../../../helpers/accountDictionary'

const AssociatedEventsList = ({ events }) => {
  return (
    <div className="simple-card simple-card--fit-content">
      <div className="simple-card__featured">Eventos relacionados</div>
      <div className="simple-card__description  scroll-list-wrapper">
        <ul className="credit-events-list">
          {events.map(({ value, description, event, id }) => (
            <div className="credit-events-list__item tooltip" key={id}>
              {description && <div className="tooltip__text tooltip__text--top-left">{description}</div>}
              <div className="credit-events-list__doc">
                {event.type}
              </div>
              <div className="credit-events-list__date">
                {moment(event.registryDate).format('D [de] MMMM YYYY')}
              </div>
              <div className="credit-events-list__value">
                {currencyFormat(value)}
                <span className="credit-events-list__detailing">{dicFormat(event.account.detailing).slice(27)}</span>
              </div>
              <div className="credit-events-list__actions">
                <Link to={`/account-events/${event.account.id}`} className="btn-icon"><MdList /></Link>
              </div>
            </div>
          ))}
        </ul>
        {!events.length && <div>Nenhum valor atribuido</div>}
      </div>
    </div>
  )
}

export default AssociatedEventsList