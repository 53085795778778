import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { Link } from 'react-router-dom'
import { currencyFormat } from '../../helpers'
import MainContaintLoading from '../../components/MainContaintLoading'

const ALL_ACCOUNTS_AGG_AUXILIARES = gql`
  query allAccountsAggAuxiliaries {
    allAccountsAggAuxiliaries {
      unitCode
      number
      sum
      count  
    }
  }
`

const AccountList = () => {
  const queryResponse = useQuery(ALL_ACCOUNTS_AGG_AUXILIARES)

  return (
    <>
      <header className="header">Lista de contas</header>
      <main className="main">
        <ComponentRenderer 
          { ... queryResponse }
        />
      </main>
      <footer className="footer"></footer>
    </>
  )
}

const ComponentRenderer = ({ loading, error, data }) => {
  if (loading) return <MainContaintLoading />
  if (error) return <div>Erro ao tentar recuperar informações das contas!</div>
  if (data) return (
    <div className="auto-fill-grid">
      {data.allAccountsAggAuxiliaries.map((account, idx) => (
        <Link key={idx} className="accounts-list-card" to={`/accounts-list/${account.unitCode}/${account.number}`}>
          <div className="accounts-list-card__head">{ account.unitCode }</div>
          <div className="accounts-list-card__subhead">{ account.number }</div>
          <div className="accounts-list-card__sum">{ currencyFormat(account.sum) }</div>
          <div className="accounts-list-card__count">em { account.count } conta(s) aux.</div>
        </Link>
      ))}
    </div>
  )
}

export default AccountList