import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'
import { MdRemoveRedEye, MdDeleteForever, MdEdit } from 'react-icons/md'

import { DELETE_ACQUISITION, SHOW_ACQUISITION_FORM_MODAL, ALL_ACQUISITIONS } from '../gqls'
import { ADD_ALERT } from '../../../apollo/gqls'
import { currencyFormat } from '../../../helpers'

const capitalize = _.flow([ _.lowerCase, _.capitalize ])
const onError = error => console.log(error)

const AcquisitionCard = ({ name, description, type, startDate, planStartDate, endDate, planEndDate, id, sectionId, associatedAccountEvents }) => {
  console.log(associatedAccountEvents)
  const validStartDate = startDate || planStartDate
  const validEndDate = endDate || planEndDate

  const duration = moment(validEndDate).diff(validStartDate, 'days')
  const associated = associatedAccountEvents.reduce((acc, cur) => {
    const val = cur.value
    return {
      credit: val > 0 ? acc.credit + val : acc.credit,
      debit: val < 0 ? acc.debit + val : acc.debit
    }
  }, { credit: 0, debit: 0 })

  return (
    <div className="simple-card">
      <div className="simple-card__featured">{name}</div>
      <div className="simple-card__description">{description}</div>

      <div className="simple-card__labels">
        <span className="label">{capitalize(type)}</span>
        <span className="label">{duration} dias</span>
      </div>
      
      <div className="simple-card__labels">
        <span className="label">{currencyFormat(associated.credit)} positivado</span>
        <span className="label">{currencyFormat(associated.debit)} negativado</span>
      </div>

      <div className="simple-card__actions">
        <EditButton acquisitionId={id} sectionId={sectionId} />
        { (associatedAccountEvents.length === 0) && <DeleteButton acquisitionId={id} sectionId={sectionId} name={name} /> }
        <Link to={`/acquisition/${id}`} className="btn-icon"><MdRemoveRedEye /></Link>
      </div>
    </div>
  )
}

const EditButton = ({ acquisitionId, sectionId }) => {
  const [showAcquisitionFormModal] = useMutation(SHOW_ACQUISITION_FORM_MODAL, {
    variables : { sectionId, acquisitionId }
  })

  return (
    <button className="btn-icon" onClick={() => showAcquisitionFormModal()}>
      <MdEdit />
    </button>
  )
}

const DeleteButton = ({ name, acquisitionId, sectionId, ...args }) => {
  const [addAlert] = useMutation(ADD_ALERT)

  const [deleteAcquisition, { loading }] = useMutation(DELETE_ACQUISITION, {
    onError,
    variables: { id: acquisitionId },
    onCompleted: () => {
      addAlert({
        variables: {
          type: "SUCCESS_ALERT",
          message: "Tarefa removida com sucesso!"
        }
      })
    },
    update: (cache, { data }) => {
      const query = {
        query: ALL_ACQUISITIONS,
        variables: {
          section: sectionId
        }
      }

      const cacheData = _.cloneDeep(cache.readQuery(query))
      cacheData.allAcquisitions.edges = cacheData.allAcquisitions.edges.filter(({node}) => node.id !== acquisitionId)

      cache.writeQuery({
        ...query,
        data: cacheData
      })
    }
  })

  const deleteHandler = () => {
    let typed = prompt(`Digite o nome do processo para confirmar: ${name}`)
    if (typed === name)
      deleteAcquisition()
    else addAlert({
      variables: {
        type: "ERROR_ALERT",
        message: "Nome digitido não coincide com o do processo!"
      }
    })
  }

  return (
    <button disabled={loading} className="btn-icon" onClick={deleteHandler} {...args}>
      <MdDeleteForever />
    </button>
  )
}

export default AcquisitionCard