import moment from 'moment'
import _ from 'lodash'

const options = {
  columnWidth: 38,
  headerHeight: 60,
  barHeight: 25,
  padding: 18
}

const getMinDate = tasks => moment(_.minBy(tasks, 'start').start).subtract(4, 'days').toISOString()
const getMaxDate = tasks => moment(_.maxBy(tasks, 'end').end).add(30, 'days').toISOString()

const barX = (task, minDate) => (moment(task.start).diff(minDate, 'days') * options.columnWidth)
const barWidth = task => (moment(task.end).diff(task.start, 'days') * options.columnWidth)

const barY = idx => {
  const { headerHeight, padding, barHeight } = options
  return headerHeight + (padding / 2) + (idx * (barHeight + padding) + padding / 2)
}
const rowY = idx => {
  const { headerHeight, padding, barHeight } = options
  return headerHeight + (padding / 2) + (idx * (barHeight + padding))
}

export {
  options,
  barX,
  barY,
  barWidth,
  getMaxDate,
  getMinDate,
  rowY
}