import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import moment from 'moment'
import _ from 'lodash'
// import { GiSave } from 'react-icons/gi'
// import { MdRefresh } from 'react-icons/md'

import { currencyFormat } from '../../helpers'
import { 
  SAVE_ACCOUNT_EVENT_DESCRIPTION, 
  ADD_ALERT, 
  ACCOUNT_EVENT_DESCRIPTION_MODAL,
  HIDE_ACCOUNT_EVENT_DESCRIPTION_MODAL,
  ACCOUNT_EVENT
} from './gqls'

const onError = error => console.log(error)

const EventDescriptionModal = () => {
  const [ description, setDescription ] = useState('')
  const [ section, setSection ] = useState('')

  const { data } = useQuery(ACCOUNT_EVENT_DESCRIPTION_MODAL)
  const { isHidden, eventId } = data.accountEventDescriptionModal
  const accountEventQuery = useQuery(ACCOUNT_EVENT, {
    skip: !eventId,
    variables: {
      id: eventId
    },
    onError,
    onCompleted: data => {
      const savedDescription = _.get(data, ['accountEvent', 'description'], '')
      const savedSectionId = _.get(data, ['accountEvent', 'assignedTo', 'id'], '')
      setDescription(savedDescription || '')
      setSection(savedSectionId)
    }
  })

  const [ hideAccountEventDescriptionModal ] = useMutation(HIDE_ACCOUNT_EVENT_DESCRIPTION_MODAL)
  const [ addAlert ] = useMutation(ADD_ALERT)
  const [ saveAccountEventDescription, saveTracking ] = useMutation(SAVE_ACCOUNT_EVENT_DESCRIPTION, {
    onError, 
    onCompleted: () => {
      addAlert({ variables: { message: 'Descrição salva!', type: 'SUCCESS_ALERT' } })
    }
  })

  const saveHandler = () => {
    saveAccountEventDescription({
      variables: {
        id: eventId,
        sectionId: section,
        description
      }
    })
  }

  const fecharModalHandler = () => {
    hideAccountEventDescriptionModal()
  }

  const sectionHandler = e => {
    setSection(e.target.value)
  }

  const loading = saveTracking.loading || accountEventQuery.loading
  const unitOrigin = _.get(accountEventQuery, ['data', 'accountEvent', 'unitOrigin'], '')
  const documentNumber = _.get(accountEventQuery, ['data', 'accountEvent', 'documentNumber'], '')
  const registryDate = _.get(accountEventQuery, ['data', 'accountEvent', 'registryDate'], '')
  const value = _.get(accountEventQuery, ['data', 'accountEvent', 'value'], '')
  const sections = _.get(accountEventQuery, ['data', 'allSections', 'edges'], [])

  return (
    <div className={`modal-wrapper ${isHidden? '': 'show'}`}>
      <div className={`modal`}>
        <div className="modal__header">{unitOrigin !== '' ? `${documentNumber} / ${unitOrigin} de ${moment(registryDate).format("D [de] MMMM YYYY")} (${currencyFormat(value)})` : ''}</div>
        <div className="form-field col-1-tablet">
          <select disabled={loading} className="form-field__select" name="section" value={section} onChange={sectionHandler}>
            <option value=""></option>
            {sections.map(({ node }) => (
              <option value={node.id} key={node.id}>{node.name}</option>
            ))}
          </select>
          <label className="form-field__label" htmlFor="section">Setor</label>
        </div>
        <div className="form-field ">
          <textarea disabled={loading} name="description" rows="5" className="form-field__textarea" value={description} onChange={e => setDescription(e.target.value)} />
          <label htmlFor="description" className="form-field__label">Descrição</label>
        </div>
        <div className="form-actions">
          <button disabled={loading} className="btn" onClick={fecharModalHandler}>Fechar</button>
          <button disabled={loading} className="btn" onClick={saveHandler}>Salvar</button>
        </div>
      </div>
    </div>
  )
}

export default EventDescriptionModal
