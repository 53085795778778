import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const IS_LOGGED_IN = gql`
  query isLoggedIn {
    isLoggedIn @client
  }
`

const PrivateRoute = ({ component, ...rest }) => {
  const { data } = useQuery(IS_LOGGED_IN)
  
  if (data.isLoggedIn)
    return <Route component={component} { ...rest } />

  return (
    <Route 
      render={({ location }) => (
        <Redirect to={{ pathname: "/login", state: { from: location } }} />
      )} />
  )
}

export default PrivateRoute