import { truncate, camelCase } from 'lodash'

import { colors } from '../../helpers'
import { currencyFormat } from '../../helpers'

const jump = (h) => {
  const url = window.location.href
  window.location.href = "#" + h
  window.history.replaceState(null, null, url)
}

const topChart = ({labels, values, truncateXLen}) => {
  return ({
    type: 'bar',
    data: {
      labels: labels,
      datasets: [{
        label: 'Somatório',
        backgroundColor: colors,
        data: values
      }]
    },
    options: {
      // animation: {
      //   duration: 0
      // },
      onClick: (e, [el]) => {
        if (el && el._model)
          jump(camelCase(el._model.label))
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem) => currencyFormat(tooltipItem.value)
        }
      },
      maintainAspectRatio: false,
      legend: { display: false },
      title: {
        display: true,
        text: "Somatório por elemento de despesa (Rep. logarítimica)"
      },
      scales: {
        xAxes: [{
          ticks: {
            autoSkip: true,
            callback: value => truncate(value, { length: truncateXLen })
          }
        }],
        yAxes: [{
          type: 'logarithmic',
          ticks: {
            beginAtZero: true,
            autoSkip: true,
            autoSkipPadding: 20,
            callback: (value) => currencyFormat(value)
          },
        }]
      }
    }
  })
}

export {
  topChart
}