import { capitalize } from 'lodash'

const element = account => {
  if (account.length === 31) {
    const key = account.slice(-6, -4)
    return capitalize(elDic[key])
  }

  if (account.length === 2)
    return capitalize(elDic[account])

  if (account.length === 6)
    return capitalize(elDic[account.slice(-2)])

  return 'Definição não encontrada'
}

const action = account => {
  if (account.length === 31 || account.length === 33) {
    const key = account.slice(13, 17)
    return actionDic[key]
  }

  if (account.length === 4)
    return actionDic[account]

  return 'Definição não encontrada'
}

const source = account => {
  if (account.length === 31 || account.length === 33) {
    const key = account.slice(-4, -2)
    return capitalize(sourceDic[key])
  }

  if (account.length === 4) {
    const key = account.slice(0, 2)
    return capitalize(sourceDic[key])
  }

  if (account.length === 2) 
    return capitalize(sourceDic[account])

  return 'Definição não encontrada'
}

const subfunction = account => {
  if (account.length === 31 || account.length === 33) {
    const key = account.slice(7, 10)
    return { key, description: capitalize(subfunctionDic[key]) }
  }
  return { key: action, description: 'Definição não encontrada'}
}

const program = account => {
  if (account.length === 31 || account.length === 33) {
    const key = account.slice(10, 13)
    return { key, description: programDic[key] }
  }
  return { key: action, description: 'Definição não encontrada'}
}

const format = account => {
  if (account.length === 31)  
    return account.replace(/(\d{1})(\d{4})(\d{2})(\d{3})(\d{3})(\d{4})(\d{4})(\d{6})(\d{2})(\d{2})/, '$1 . $2 . $3 . $4 . $5 . $6 . $7 . $8 . $9 . $10')

  if (account.length === 33)
    return account.replace(/(\d{1})(\d{4})(\d{2})(\d{3})(\d{3})(\d{4})(\d{4})(\d{6})(\d{2})(\d{2})(\d{2})/, '$1 . $2 . $3 . $4 . $5 . $6 . $7 . $8 . $9 . $10 . $11')

  return account
}

export {
  element,
  format,
  source,
  action,
  subfunction,
  program
}

const programDic = {
  "026": "Enfrentamento dos efeitos da Pandemia de COVID-19",
  "155": "Promoção de Defesa Civil",
  "160": "Atendimento Pré-Hospitalar",
  "705": "Apoio às Políticas Públicas"
}

const subfunctionDic = {
  "031":	"AÇÃO LEGISLATIVA",
  "032":	"CONTROLE EXTERNO",
  "061":	"AÇÃO JUDICIÁRIA",
  "062":	"DEFESA DO INTERESSE PÚBLICO NO PROCESSO JUDICIÁRIO",
  "091":	"DEFESA DA ORDEM JURÍDICA",
  "092":	"REPRESENTAÇÃO JUDICIAL E EXTRAJUDICIAL",
  "121":	"PLANEJAMENTO E ORÇAMENTO",
  "122":	"ADMINISTRAÇÃO GERAL",
  "123":	"ADMINISTRAÇÃO FINANCEIRA",
  "124":	"CONTROLE INTERNO",
  "125":	"NORMATIZAÇÃO E FISCALIZAÇÃO",
  "126":	"TECNOLOGIA DA INFORMAÇÃO",
  "127":	"ORDENAMENTO TERRITORIAL",
  "128":	"FORMAÇÃO DE RECURSOS HUMANOS",
  "129":	"ADMINISTRAÇÃO DE RECEITAS",
  "130":	"ADMINISTRAÇÃO DE CONCESSÕES",
  "131":	"COMUNICAÇÃO SOCIAL",
  "151":	"DEFESA AÉREA",
  "152":	"DEFESA NAVAL",
  "153":	"DEFESA TERRESTRE",
  "181":	"POLICIAMENTO",
  "182":	"DEFESA CIVIL",
  "183":	"INFORMAÇÃO E INTELIGÊNCIA",
  "211":	"RELAÇÕES DIPLOMÁTICAS",
  "212":	"COOPERAÇÃO INTERNACIONAL",
  "241":	"ASSISTÊNCIA AO IDOSO",
  "242":	"ASSISTÊNCIA AO PORTADOR DE DEFICIÊNCIA",
  "243":	"ASSISTÊNCIA À CRIANÇA E AO ADOLESCENTE",
  "244":	"ASSISTÊNCIA COMUNITÁRIA",
  "271":	"PREVIDÊNCIA BÁSICA",
  "272":	"PREVIDÊNCIA DO REGIME ESTATUTÁRIO",
  "273":	"PREVIDÊNCIA COMPLEMENTAR",
  "274":	"PREVIDÊNCIA ESPECIAL",
  "301":	"ATENÇÃO BÁSICA",
  "302":	"ASSISTÊNCIA HOSPITALAR E AMBULATORIAL",
  "303":	"SUPORTE PROFILÁTICO E TERAPÊUTICO",
  "304":	"VIGILÂNCIA SANITÁRIA",
  "305":	"VIGILÂNCIA EPIDEMIOLÓGICA",
  "306":	"ALIMENTAÇÃO E NUTRIÇÃO",
  "331":	"PROTEÇÃO E BENEFÍCIOS AO TRABALHADOR",
  "332":	"RELAÇÕES DE TRABALHO",
  "333":	"EMPREGABILIDADE",
  "334":	"FOMENTO AO TRABALHO",
  "361":	"ENSINO FUNDAMENTAL",
  "362":	"ENSINO MÉDIO",
  "363":	"ENSINO PROFISSIONAL",
  "364":	"ENSINO SUPERIOR",
  "365":	"EDUCAÇÃO INFANTIL",
  "366":	"EDUCAÇÃO DE JOVENS E ADULTOS",
  "367":	"EDUCAÇÃO ESPECIAL",
  "368":	"EDUCAÇÃO BÁSICA",
  "391":	"PATRIMÔNIO HISTÓRICO, ARTÍSTICO E ARQUEOLÓGICO",
  "392":	"DIFUSÃO CULTURAL",
  "421":	"CUSTÓDIA E REINTEGRAÇÃO SOCIAL",
  "422":	"DIREITOS INDIVIDUAIS, COLETIVOS E DIFUSOS",
  "423":	"ASSISTÊNCIA AOS POVOS INDÍGENAS",
  "451":	"INFRA-ESTRUTURA URBANA",
  "452":	"SERVIÇOS URBANOS",
  "453":	"TRANSPORTES COLETIVOS URBANOS",
  "481":	"HABITAÇÃO RURAL",
  "482":	"HABITAÇÃO URBANA",
  "511":	"SANEAMENTO BÁSICO RURAL",
  "512":	"SANEAMENTO BÁSICO URBANO",
  "541":	"PRESERVAÇÃO E CONSERVAÇÃO AMBIENTAL",
  "542":	"CONTROLE AMBIENTAL",
  "543":	"RECUPERAÇÃO DE ÁREAS DEGRADADAS",
  "544":	"RECURSOS HÍDRICOS",
  "545":	"METEOROLOGIA",
  "571":	"DESENVOLVIMENTO CIENTÍFICO",
  "572":	"DESENVOLVIMENTO TECNOLÓGICO E ENGENHARIA",
  "573":	"DIFUSÃO DO CONHECIMENTO CIENTÍFICO E TECNOLÓGICO",
  "601":	"PROMOÇÃO DA PRODUÇÃO VEGETAL",
  "602":	"PROMOÇÃO DA PRODUÇÃO ANIMAL",
  "603":	"DEFESA SANITÁRIA VEGETAL",
  "604":	"DEFESA SANITÁRIA ANIMAL",
  "606":	"EXTENSÃO RURAL",
  "607":	"IRRIGAÇÃO",
  "608":	"PROMOÇÃO DA PRODUÇÃO AGROPECUÁRIA",
  "609":	"DEFESA AGROPECUÁRIA",
  "631":	"REFORMA AGRÁRIA",
  "632":	"COLONIZAÇÃO",
  "661":	"PROMOÇÃO INDUSTRIAL",
  "662":	"PRODUÇÃO INDUSTRIAL",
  "663":	"MINERAÇÃO",
  "664":	"PROPRIEDADE INDUSTRIAL",
  "665":	"NORMALIZAÇÃO E QUALIDADE",
  "691":	"PROMOÇÃO COMERCIAL",
  "692":	"COMERCIALIZAÇÃO",
  "693":	"COMÉRCIO EXTERIOR",
  "694":	"SERVIÇOS FINANCEIROS",
  "695":	"TURISMO",
  "721":	"COMUNICAÇÕES POSTAIS",
  "722":	"TELECOMUNICAÇÕES",
  "751":	"CONSERVAÇÃO DE ENERGIA",
  "752":	"ENERGIA ELÉTRICA",
  "753":	"COMBUSTÍVEIS MINERAIS",
  "754":	"BIOCOMBUSTÍVEIS",
  "781":	"TRANSPORTE AÉREO",
  "782":	"TRANSPORTE RODOVIÁRIO",
  "783":	"TRANSPORTE FERROVIÁRIO",
  "784":	"TRANSPORTE HIDROVIÁRIO",
  "785":	"TRANSPORTES ESPECIAIS",
  "811":	"DESPORTO DE RENDIMENTO",
  "812":	"DESPORTO COMUNITÁRIO",
  "813":	"LAZER",
  "841":	"REFINANCIAMENTO DA DÍVIDA INTERNA",
  "842":	"REFINANCIAMENTO DA DÍVIDA EXTERNA",
  "843":	"SERVIÇO DA DÍVIDA INTERNA",
  "844":	"SERVIÇO DA DÍVIDA EXTERNA",
  "845":	"OUTRAS TRANSFERÊNCIAS",
  "846":	"OUTROS ENCARGOS ESPECIAIS",
  "847":	"TRANSFERÊNCIAS PARA A EDUCAÇÃO BÁSICA",
  "999":	"RESERVA DE CONTINGÊNCIA"
}

const sourceDic = {
  "10":	"RECURSOS ORDINÁRIOS",
  "12":	"OPERAÇÕES DE CRÉDITO CONTRATUAIS - SWAP",
  "20":	"RECURSOS CONSTITUC.VINCULADOS AOS MUNICÍPIOS",
  "21":	"COTA ESTADUAL DO SALÁRIO EDUCAÇÃO - QESE",
  "22":	"RECURSOS DO SISTEMA ÚNICO DE SAÚDE - SUS",
  "23":	"FUNDO DE MANUTENCAO E DESENVOLVIMENTO DA EDUCACAO BASICA - FUNDEB",
  "24":	"CONVÊNIOS COM A UNIÃO E SUAS ENTIDADES",
  "25":	"OPERAÇÕES DE CRÉDITO CONTRATUAIS",
  "26":	"TAXA FLORESTAL - ADMINISTRAÇÃO INDIRETA",
  "27":	"TAXA DE SEGURANÇA PÚBLICA",
  "28":	"TAXA DE FISCALIZAÇÃO JUDICIÁRIA",
  "29":	"TAXA DE EXPEDIENTE - ADMINISTRAÇÃO DIRETA",
  "30":	"CONTRIBUIÇÃO À APOSENTADORIA",
  "31":	"UTILIZAÇÃO DE RECURSOS HÍDRICOS",
  "32":	"EXPLORAÇÃO DE RECURSOS MINERAIS",
  "33":	"EXPLORAÇÃO E PRODUÇÃO DE PETRÓLEO  E GÁS NATURAL",
  "34":	"NOTIFICAÇÃO DE INFRAÇÃO DE TRÂNSITO",
  "35":	"CONTRIBUIÇÃO AO FUNDESE",
  "36":	"TRANSFERÊNCIAS DE RECURSOS DA UNIÃO VINCULADOS À EDUCAÇÃO",
  "37":	"TRANSFERÊNCIAS DE RECURSOS DA UNIÃO VINCULADOS À SAÚDE",
  "38":	"TRANSFERÊNCIAS DE RECURSOS DA UNIÃO VINCULADOS AO ESPORTE",
  "39":	"MULTAS PECUNIÁRIAS E JUROS DE MORA FIXADOS EM SENTENÇAS JUDICIAIS",
  "40":	"RECURSOS FUNDOS EXTINTOS-LEI Nº 13.848/2001",
  "41":	"CESSÃO DE CRÉDITOS",
  "42":	"CONTRIBUIÇÃO PATRONAL PARA O FUNFIP",
  "43":	"CONTRIBUIÇÃO DO SERVIDOR PARA O FUNFIP",
  "44":	"COMPENSAÇÃO FINANCEIRA ENTRE REGIMES DE PREVIDÊNCIA",
  "45":	"DOAÇÕES DE PESSOAS FÍSICAS OU JURÍDICAS, DE INSTITUIÇÕES PRIVADAS OU DO EXTERIOR A ÓRGÃO E ENTIDADES DO ESTADO",
  "46":	"DOAÇÕES DE PESSOAS FÍSICAS OU JURÍDICAS, DE INSTITUIÇÕES PRIVADAS OU DO EXTERIOR AO TESOURO ESTADUAL",
  "47":	"ALIENAÇÃO DE BENS DE ENTIDADES ESTADUAIS",
  "48":	"ALIENAÇÃO DE BENS DO TESOURO ESTADUAL ",
  "49":	"CONTRIBUIÇÃO PATRONAL DO ESTADO AOS INSTITUTOS DE PREVIDÊNCIA",
  "50":	"CONTRIBUIÇÃO DO SERVIDOR DO ESTADO AOS INSTITUTOS DE PREVIDÊNCIA",
  "51":	"CONTRIBUIÇÃO DE INTERVENÇÃO NO DOMÍNIO ECONÔMICO/COMBUSTÍVEIS-CIDE",
  "52":	"TAXA DE CONTROLE E FISCALIZAÇÃO AMBIENTAL",
  "53":	"TAXA DE INCÊNDIO",
  "54":	"TAXA DE LICENCIAMENTO DA FAIXA DE DOMÍNIO DAS RODOVIAS",
  "55":	"TRANSFERÊNCIAS DE RECURSOS DOS MUNICÍPIOS VINCULADOS À FARMÁCIA BÁSICA - FES",
  "56":	"TRANSFERÊNCIAS DE RECURSOS DA UNIÃO VINCULADOS À ASSISTÊNCIA SOCIAL",
  "57":	"TRANSFERENCIAS DE RECURSOS DA UNIAO POR MEIO DE PORTARIA",
  "58":	"RECURSOS PARA COBERTURA DO DEFICIT ATUARIAL DO RPPS",
  "59":	"OUTROS RECURSOS VINCULADOS",
  "60":	"RECURSOS DIRETAMENTE ARRECADADOS",
  "61":	"RECURSOS DIRETAMENTE ARRECADADOS COM VINCULAÇÃO ESPECÍFICA",
  "70":	"CONVÊNIOS COM OS ESTADOS, O DISTRITO FEDERAL, OS MUNICÍPIOS, AS INSTITUIÇÕES PRIVADAS E OS ORGANISMOS DO EXTERIOR",
  "71":	"RECURSOS DO FUNDO ESTADUAL DE ERRADICACAO DA MISERIA",
  "72":	"TAXA DE FISCALIZACAO DE RECURSOS MINERARIOS",
  "73":	"ACORDOS E AJUSTES DE COOPERAÇÃO MÚTUA COM A UNIÃO E SUAS ENTIDADES. ",
  "74":	"ACORDOS E AJUSTES DE COOPERAÇÃO MÚTUA COM OS ESTADOS, O DISTRITO FEDERAL, OS MUNICÍPIOS, AS INSTITUIÇÕES PRIVADAS E OS ORGANISMOS DO EXTERIOR",
  "75":	"CONTRIBUICAO PATRONAL PARA CUSTEIO DOS PROVENTOS DOS MILITARES",
  "76":	"TAXA DE ADMINISTRACAO DO FUNPEMG",
  "77":	"TAXA DE FISCALIZAÇÃO JUDICIÁRIA E TAXAS E MULTAS JUDICIAIS",
  "78":	"CONTRIBUIÇÃO MILITAR PARA CUSTEIO DO SISTEMA DE PROTEÇÃO SOCIAL DOS MILITARES",
  "79":	"CONTRIBUIÇÃO PATRONAL PARA O FUNPREV",
  "80":	"CONTRIBUIÇÃO DO SERVIDOR PARA O FUNPREV ",
  "81":	"RECURSOS DE DEPÓSITOS JUDICIAIS LEI 21.720/15",
  "82":	"NOTIFICACAO DE INFRACAO DE TRANSITO ESTADO",
  "83":	"NOTIFICACAO DE INFRACAO DE TRANSITO FUNTRANS",
  "84":	"TRANSFERÊNCIAS DE RECURSOS DO SUS PARA A GESTÃO DO SUS",
  "85":	"TRANSFERÊNCIAS DE RECURSOS DO SUS PARA A VIGILÂNCIA EM SAÚDE",
  "86":	"TRANSFERÊNCIAS DE RECURSOS DO SUS PARA A ASSISTÊNCIA FARMACÊUTICA",
  "87":	"TRANSFERÊNCIAS DE RECURSOS DO SUS PARA A ATENÇÃO BÁSICA",
  "88":	"TRANSFERÊNCIAS DE RECURSOS DO SUS PARA INVESTIMENTOS NA REDE DE SERVIÇOS DE SAÚDE",
  "89":	"RECURSOS DECORRENTES DA OPERAÇÃO DE SECURITIZAÇÃO DOS ATIVOS DO FECIDAT",
  "90":	"RECURSOS DECORRENTES DA COBRANÇA DOS CRÉDITOS INADIMPLIDOS INSCRITOS - LEI 22.606/2017",
  "91":	"TAXA DE EXPEDIENTE - ADMINISTRAÇÃO INDIRETA",
  "92":	"TRANSFERÊNCIAS DE RECURSOS DA UNIÃO VINCULADOS À SAÚDE - BLOCO DE CUSTEIO",
  "93":	"TRANSFERÊNCIAS DE RECURSOS DA UNIÃO VINCULADOS À SAÚDE - BLOCO DE INVESTIMENTO",
  "94":	"TAXA FLORESTAL - ADMINISTRAÇÃO DIRETA",
  "95":	"RECURSOS RECEBIDOS POR DANOS ADVINDOS DE DESASTRES SOCIOAMBIENTAIS",
  "96":	" BÔNUS DE ASSINATURA DO EXCEDENTE DA CESSÃO ONEROSA PELA EXPLORAÇÃO DO PRÉ-SAL ",
  "97":	"TRANFERÊNCIAS ESPECIAIS DE RECURSOS DA UNIÃO ",
  "98":	"CONTRIBUIÇÃO MILITAR PARA CUSTEIO DO BENEFÍCIO DE ASSISTÊNCIA À SAÚDE DOS MILITARES E DEPENDENTES"
}

const elDic = {
  '01':	'APOSENTADORIAS DO RPPS, RESERVA REMUNERADA E REFORMAS DOS MILITARES',
  '03':	'PENSÕES DO RPPS E DO MILITAR',
  '04':	'CONTRATAÇÃO POR TEMPO DETERMINADO',
  '06':	'BENEFÍCIO MENSAL AO DEFICIENTE E AO IDOSO',
  '07':	'CONTRIBUIÇÃO A ENTIDADES FECHADAS DE PREVIDÊNCIA',
  '08':	'OUTROS BENEFÍCIOS ASSISTENCIAIS DO SERVIDOR E DO MILITAR',
  '10':	'SEGURO DESEMPREGO E ABONO SALARIAL',
  '11':	'VENCIMENTOS E VANTAGENS FIXAS-PESSOAL CIVIL',
  '12':	'VENCIMENTOS E VANTAGENS FIXAS - PESSOAL  MILITAR',
  '13':	'OBRIGAÇÕES PATRONAIS',
  '14':	'DIÁRIAS - CIVIL',
  '15':	'DIÁRIAS - MILITAR',
  '16':	'OUTRAS DESPESAS VARIÁVEIS - PESSOAL CIVIL',
  '17':	'OUTRAS DESPESAS VARIÁVEIS - PESSOAL MILITAR',
  '18':	'AUXÍLIO FINANCEIRO A ESTUDANTES',
  '19':	'AUXÍLIO-FARDAMENTO',
  '20':	'AUXÍLIO FINANCEIRO A PESQUISADORES ',
  '21':	'JUROS SOBRE A DÍVIDA POR CONTRATO',
  '22':	'OUTROS ENCARGOS SOBRE A DÍVIDA POR CONTRATO',
  '23':	'JUROS, DESÁGIOS E DESCONTOS DA DÍVIDA MOBILIÁRIA',
  '24':	'OUTROS ENCARGOS SOBRE A DÍVIDA MOBILIÁRIA',
  '25':	'ENCARGOS SOBRE OPERAÇÕES DE CRÉDITO POR ANTECIPAÇÃO DA RECEITA',
  '26':	'OBRIGAÇÕES DECORRENTES DE POLÍTICA MONETÁRIA',
  '27':	'ENCARGOS PELA HONRA DE AVAIS, GARANTIAS, SEGUROS E SIMILARES',
  '28':	'REMUNERAÇÃO DE COTAS DE FUNDOS AUTÁRQUICOS',
  '29':	'Distribuição de Resultado de Empresas Estatais Dependentes',
  '30':	'MATERIAL DE CONSUMO',
  '31':	'PREMIAÇÕES CULTURAIS, ARTÍSTICAS, CIENTÍFICAS, DESPORTIVAS E OUTRAS.',
  '32':	'MATERIAL, BEM OU SERVIÇO PARA DISTRIBUIÇÃO GRATUITA',
  '33':	'PASSAGENS E DESPESAS COM LOCOMOÇÃO',
  '34':	'OUTRAS DESPESAS DE PESSOAL DECORRENTES DE CONTRATOS DE TERCEIRIZAÇÃO',
  '35':	'SERVIÇOS DE CONSULTORIA',
  '36':	'OUTROS SERVIÇOS DE TERCEIROS - PESSOA FÍSICA',
  '37':	'LOCAÇÃO DE MÃO-DE-OBRA',
  '38':	'ARRENDAMENTO MERCANTIL',
  '39':	'OUTROS SERVIÇOS DE TERCEIROS - PESSOA JURÍDICA',
  '40':	'SERVIÇOS DE TECNOLOGIA DA INFORMAÇÃO E COMUNICAÇÃO -  PESSOA JURÍDICA',
  '41':	'CONTRIBUIÇÕES',
  '42':	'AUXÍLIOS',
  '43':	'SUBVENÇÕES SOCIAIS',
  '45':	'SUBVENÇÕES ECONÔMICAS',
  '46':	'AUXÍLIO-ALIMENTAÇÃO',
  '47':	'OBRIGAÇÕES TRIBUTÁRIAS E CONTRIBUTIVAS',
  '48':	'OUTROS AUXÍLIOS FINANCEIROS A PESSOAS FÍSICAS',
  '49':	'AUXÍLIO-TRANSPORTE',
  '51':	'OBRAS E INSTALAÇÕES',
  '52':	'EQUIPAMENTOS E MATERIAL PERMANENTE',
  '53':	'APOSENTADORIAS DO RGPS - ÁREA RURAL',
  '54':	'APOSENTADORIAS DO RGPS - ÁREA URBANA',
  '55':	'PENSÕES DO RGPS - ÁREA RURAL',
  '56':	'PENSÕES DO RGPS - ÁREA URBANA',
  '57':	'OUTROS BENEFÍCIOS DO RGPS - ÁREA RURAL',
  '58':	'OUTROS BENEFÍCIOS DO RGPS - ÁREA URBANA',
  '59':	'PENSÕES ESPECIAIS ',
  '61':	'AQUISIÇÃO DE IMÓVEIS',
  '62':	'AQUISIÇÃO DE PRODUTOS PARA REVENDA',
  '63':	'AQUISIÇÃO DE TÍTULOS DE CRÉDITO',
  '64':	'AQUISIÇÃO DE TÍTULOS REPRESENTATIVOS DE CAPITAL JÁ INTEGRALIZADO',
  '65':	'CONSTITUIÇÃO OU AUMENTO DE CAPITAL DE EMPRESAS',
  '66':	'CONCESSÃO DE EMPRÉSTIMOS E FINANCIAMENTOS',
  '67':	'DEPÓSITOS COMPULSÓRIOS',
  '70':	'RATEIO PELA PARTICIPAÇÃO EM CONSÓRCIO PÚBLICO',
  '71':	'PRINCIPAL DA DÍVIDA CONTRATUAL RESGATADO',
  '72':	'PRINCIPAL DA DÍVIDA MOBILIÁRIA RESGATADO',
  '73':	'CORREÇÃO MONETÁRIA OU CAMBIAL DA DÍVIDA CONTRATUAL RESGATADA',
  '74':	'CORREÇÃO MONETÁRIA OU CAMBIAL DA DÍVIDA MOBILIÁRIA RESGATADA',
  '75':	'CORREÇÃO MONETÁRIA DA DÍVIDA DE OPERAÇÕES DE CRÉDITO POR ANTECIPAÇÃO DA RECEITA',
  '76':	'PRINCIPAL CORRIGIDO DA DÍVIDA MOBILIÁRIA REFINANCIADO',
  '77':	'PRINCIPAL CORRIGIDO DA DÍVIDA CONTRATUAL REFINANCIADO',
  '81':	'DISTRIBUIÇÃO CONSTITUCIONAL OU LEGAL DE RECEITAS',
  '82':	'APORTE DE RECURSOS PELO PARCEIRO PÚBLICO EM FAVOR DO PARCEIRO PRIVADO DECORRENTE DE CONTRATO DE PARCERIA PÚBLICO-PRIVADA - PPP',
  '83':	'DESPESAS DECORRENTES DE CONTRATO DE PARCERIA PÚBLICO-PRIVADA - PPP, EXCETO SUBVENÇÕES ECONÔMICAS, APORTE E FUNDO GARANTIDOR',
  '84':	'DESPESAS DECORRENTES DA PARTICIPAÇÃO EM FUNDOS, ORGANISMOS, OU ENTIDADES ASSEMELHADAS, NACIONAIS E INTERNACIONAIS',
  '91':	'SENTENÇAS JUDICIAIS',
  '92':	'DESPESAS DE EXERCÍCIOS ANTERIORES',
  '93':	'INDENIZAÇÕES E RESTITUIÇÕES',
  '94':	'INDENIZAÇÕES E RESTITUIÇÕES TRABALHISTAS',
  '95':	'INDENIZAÇÃO PELA EXECUÇÃO DE TRABALHOS DE CAMPO',
  '96':	'RESSARCIMENTO DE DESPESAS DE PESSOAL REQUISITADO',
  '97':	'APORTE PARA COBERTURA DO DEFICIT ATUARIAL DO RPPS',
  '98':	'INDENIZAÇÕES E RESTITUIÇÕES TRABALHISTAS',
  '99':	'A CLASSIFICAR'
}

const actionDic = {
  "1005": "Gestão da resposta à Pandemia de COVID-19",
  "1071": "Potencialização do Serviço de Prevenção Contra Incêndio e Pânico.",
  "2079": "Assistência Médica, Odontológica, Psicológica e Fisioterápica aos Bombeiros Militares.",
  "2083": "Reforma e Manutenção de Unidades Prediais.",
  "2500": "Assessoramento e Gerenciamento de Políticas Públicas.",
  "4464": "Bombeiros nas Escolas.",
  "4469": "Ampliação do Sistema de Comunicação e Tecnologia da Informação.",
  "4470": "Sistema de TIC para Resposta a Desastres.",
  "4471": "Gestão da Prevenção, Mitigação e Preparação de Desastres.",
  "4472": "Gestão da Resposta a Desastres.",
  "4479": "Expansão do CBMMG Através da Criação e Ampliação de Unidades Prediais.",
  "4483": "Suporte Básico e Avançado de Vida.",
  "4484": "Recrutamento, Treinamento e Formação de Bombeiros.",
  "7007": "Proventos de Inativos Militares."
}