import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, from, split } from 'apollo-boost'
import { getMainDefinition } from 'apollo-utilities'
import { WebSocketLink } from 'apollo-link-ws'
import { onError } from 'apollo-link-error'

import { resolvers } from './resolvers'
import initialState from './initialState'
import typeDefs from './typeDefs'
import config from '../config'
import { getTokens, saveTokens } from '../helpers'
import { ADD_ALERT } from './gqls'

const cache = new InMemoryCache()

const authMiddleware = new ApolloLink((operation, forward) => {
  const { accessToken, refreshToken } = getTokens()
  operation.setContext({
    headers: {
      "x-access-token": accessToken,
      "x-refresh-token": refreshToken
    }
  })
  return forward(operation)
})

const wsLink = new WebSocketLink({
  uri: config.graphql.SUBSCRIPTION_URL,
  options: {
    reconnect: true
  }
})

const httpLink = new HttpLink({
  uri: config.graphql.URL,
  headers: {
    "x-access-token": getTokens().accessToken,
    "x-refresh-token": getTokens().refreshToken
  },
  fetch: async (uri, options) => {
    const initialRequest = await fetch(uri, options)
    const { headers } = initialRequest
    const accessToken = headers.get("x-access-token")
    const refreshToken = headers.get("x-refresh-token")
    if (accessToken && refreshToken)
      saveTokens({ accessToken, refreshToken })

    return initialRequest
  }
})

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.map(async alert => await client.mutate({
      mutation: ADD_ALERT,
      variables: {
        message: alert.message,
        type: 'ERROR_ALERT'
      }
    }))
  }
})


const client = new ApolloClient({
  cache: cache,
  link: from([errorLink, authMiddleware, link]),
  resolvers,
  typeDefs
})

cache.writeData(initialState)

export default client