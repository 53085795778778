import _ from 'lodash'

import { genID, saveTokens, deleteTokens, ganttDataTransform } from '../../helpers'
import * as dic from '../../helpers/accountDictionary'
import { DELETE_ALERT, GET_ALERTS } from '../gqls'

const cutElement = account => account.slice(21, 27)
const sumReducer = (acc, cur) => acc + cur.balance

export const resolvers = {

  Acquisition: {
    totalIn: (root) => {
      const associataedValues = _.get(root,['associatedAccountEvents'], [])
      return associataedValues.reduce((acc, { value }) => (value > 0) ? acc + value : acc, 0)
    },
    totalOut: (root) => {
      const associataedValues = _.get(root, ['associatedAccountEvents'], [])
      return associataedValues.reduce((acc, { value }) => (value < 0) ? acc + value : acc, 0)
    }
  },

  AccountConnection: {
    groupByElement: ({ edges = [] }) => {
      const aggregated = edges.reduce((acc, { node }) => {
        let element = cutElement(node.detailing)
        let earlier = _.get(acc, [element, 'nodes'], [])
        return _.set(acc, [element, 'nodes'], _.concat([node], earlier))
      }, {})
  
      const aggKeys = _.keys(aggregated)
      const labels = aggKeys.map(l => dic.element(l))
      const values = aggKeys.map(key => aggregated[key].nodes.reduce(sumReducer, 0.0))
      
      return {
        labels,
        values,
        aggregated,
        __typename: "GroupByElementExtention"
      }
    }
  },

  AccountEventConnection: {
    timeChartData: ({ edges = [] }) => {
      const hasDateKey = _.has(edges, [0, 'node', 'registryDate'])
      const hasValueKey = _.has(edges, [0, 'node', 'value'])
      
      if (!hasDateKey || !hasValueKey) return []

      let lastSum = 0.0
      const data = edges.map(({ node }) => ({ 
        x: node.registryDate, 
        y: (lastSum += node.value),
        __typename: "TimeChartData"
      }))

      return data
    },

    typeChartData: ({ edges = [] }) => {
      const hasValueKey = _.has(edges, [0, 'node', 'value'])
      const hasTypeKey = _.has(edges, [0, 'node', 'type'])

      if (!hasValueKey || !hasTypeKey) return { labels: [], values: [] }

      const accumulated = edges.reduce((acc, { node }) => {
        let sum = _.get(acc, node.type, 0) + Math.abs(node.value)
        return _.set(acc, node.type, sum)
      }, {})

      return { 
        labels: _.keys(accumulated), 
        values: _.values(accumulated),
        __typename: "BarChartData"
      }
    },

    distinctTypes: ({ edges = [] }) => {
      const hasTypeKey = _.has(edges, [0, 'node', 'type'])
      return hasTypeKey ? _.uniq(edges.map(({ node }) => node.type)) : []
    }
  },

  Mutation: {

    deleteAlert: (root, { id }, { cache }) => {
      const previous = cache.readQuery({ query: GET_ALERTS })
      const alerts = previous.alerts.filter(alert => alert.id !== id)
      cache.writeData({ data: { alerts }})
    },

    addAlert: (root, { type, message, time = 3000 }, { cache, client }) => {
      const previous = cache.readQuery({ query: GET_ALERTS })
      const id = genID({ type, message })
      
      const alerts = [...previous.alerts, { type, message, time, id, __typename: 'AlertClient' }]
      
      setTimeout(() => {
        client.mutate({ mutation: DELETE_ALERT, variables: { id } })
      }, time)

      cache.writeData({ data: { alerts } })
      return null
    },

    setTokens: (root, { accessToken, refreshToken }, { cache }) => {
      saveTokens({ accessToken, refreshToken })
      cache.writeData({ data: { isLoggedIn: true } })
    },

    logout: (root, data, { cache }) => {
      deleteTokens()
      cache.writeData({ data: { isLoggedIn: false } })
    },

    showAccountEventModal: (root, { eventId }, { cache }) => {
      cache.writeData({
        data: {
          accountEventModal: { isHidden: false, eventId, __typename: 'AccountEventModalClient' }
        }
      })
    },

    hideAccountEventModal: (root, data, { cache }) => {
      cache.writeData({
        data: {
          accountEventModal: { isHidden: true, eventId: null, __typename: 'AccountEventModalClient' }
        }
      })
    },

    showAccountEventDescriptionModal: (root, { eventId }, { cache }) => {
      cache.writeData({
        data: {
          accountEventDescriptionModal: { isHidden: false, eventId, __typename: 'AccountEventDescriptionModalClient' }
        }
      })
    },

    hideAccountEventDescriptionModal: (root, data, { cache }) => {
      cache.writeData({
        data: {
          accountEventDescriptionModal: { isHidden: true, eventId: null, __typename: 'AccountEventDescriptionModalClient' }
        }
      })
    },

    hideTaskFormModal: (root, data, { cache }) => {
      cache.writeData({
        data: {
          taskFormModal: { 
            isHidden: true, 
            taskId: null,
            isCreating: null,
            acquisitionId: null,
            __typename: 'TaskFormModalClient' 
          }
        }
      })
    },

    showTaskFormModal: (root, { taskId, acquisitionId }, { cache }) => {
      cache.writeData({
        data: {
          taskFormModal: {
            isHidden: false,
            isCreating: taskId ? false : true,
            taskId: taskId || '',
            acquisitionId: acquisitionId || '',
            __typename: 'TaskFormModalClient'
          }
        }
      })
    },

    showAcquisitionFormModal: (root, { sectionId, acquisitionId }, { cache }) => {
      cache.writeData({
        data: {
          acquisitionFormModal: { 
            isHidden: false, 
            isCreating: acquisitionId ? false : true,
            sectionId,
            acquisitionId: acquisitionId || null,
            __typename: 'AcquisitionFormModalClient' 
          }
        }
      })
    },

    hideAcquisitionFormModal: (root, data, { cache }) => {
      cache.writeData({
        data: {
          acquisitionFormModal: { 
            isHidden: true, 
            sectionId: null,
            isCreating: null,
            acquisitionId: null,
            __typename: 'AcquisitionModalFormClient' 
          }
        }
      })
    },

    showGanttModal: (root, { data }, { cache }) => {
      cache.writeData({
        data: {
          ganttModal: {
            isHidden: false,
            tasks: data.map(ganttDataTransform),
            __typename: 'GanttModal'
          }
        }
      })
    },

    hideGanttModal: (root, data, {cache}) => {
      cache.writeData({
        data: {
          ganttModal: {
            isHidden: true,
            tasks: null,
            __typename: 'GanttModal'
          }
        }
      })
    }
  }
}