import React, {useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import _ from 'lodash'

import { ACCOUNT_EVENT_MODAL, HIDE_ACCOUNT_EVENT_MODAL, ACCOUNT_EVENT, ASSOCIATE_ACCOUNT_EVENT } from './gqls'
import { ADD_ALERT } from '../../apollo/gqls'
import SelectAcquisition from './SelectAcquisition'
import AssociationList from './AssociationList'

const onError = error => console.log(error)
const getMinMaxTo = arg => {
  if (typeof arg === 'undefined') return [0, 0]
  const value = parseFloat(arg)
  if (value > 0) return [0.1, value]
  else return [value, -0.1]
}

const AccountEventModal = () => {
  const [ description, setDescription ] = useState('')
  const [ value, setValue ] = useState(0)
  const [ acquisitionId, setAcquisitionId ] = useState(null)
  const [ hasError, setHasError ] = useState(false)

  const resetState = () => {
    setDescription('')
    setHasError(false)
  }

  const modalQuery = useQuery(ACCOUNT_EVENT_MODAL)
  const [ addAlert ] = useMutation(ADD_ALERT)
  const [ associate, associateQuery ] = useMutation(ASSOCIATE_ACCOUNT_EVENT, {
    onError,
    onCompleted: () => {
      addAlert({
        variables: { message: "Valor associado.", type: 'SUCCESS_ALERT' }
      })
      resetState()
    }
  })
  const { isHidden, eventId } = modalQuery.data.accountEventModal
  const { data, loading } = useQuery(ACCOUNT_EVENT, {
    skip: !eventId,
    variables: {
      id: eventId
    }
  })

  useEffect(() => {
    if (data) setValue(data.accountEvent.balance.toFixed(2))
  }, [data])

  const [hideAccountEventModal] = useMutation(HIDE_ACCOUNT_EVENT_MODAL)

  const associations = _.get(data, ['accountEvent', 'associations'], [])
  const balance = _.get(data, ['accountEvent', 'balance'])
  const hasValue = (parseFloat(balance) !== 0)
  const [min, max] = getMinMaxTo(balance)

  const fecharModalHandler = () => {
    resetState()
    hideAccountEventModal()
  }
  const addHandler = () => {
    if (!value || !acquisitionId)
      return setHasError(true)

    setHasError(false)
    associate({
      variables: {
        accountEventId: eventId,
        acquisitionId,
        value: parseFloat(value),
        description
      }
    })
  }
  const valueChangeHandler = e => {
    const val = e.target.value.match(/(\d*\.?\d{0,2})/)[0]
    const clamped = _.clamp(_.toNumber(val), min, max)
    setValue((_.toNumber(val) === clamped) ? val : clamped)
  }
  
  return (

    <div className={`modal-wrapper ${isHidden ? '' : 'show'}`}>
      <div className={`modal ${hasError?'error':''}`}>
        <div className="modal__header">Atribuir valor à procedimento</div>
        <div className={`grid grid--gap-sm grid--2 hidden ${hasValue?'show':''}`}>
          <SelectAcquisition onChange={id => setAcquisitionId(id)} eventId={eventId} />
          <div className="form-field">
            <input disabled={loading} required className="form-field__input" min={min} max={max} type="number" step="0.01" onChange={valueChangeHandler} value={value} />
            <label htmlFor="value" className="form-field__label">Valor</label>
          </div>
          <div className="form-field ">
            <textarea name="description" rows="5" className="form-field__textarea" value={description} onChange={e => setDescription(e.target.value)} />
            <label htmlFor="description" className="form-field__label">Descrição</label>
          </div>
          <div className="form-actions">
            <button disabled={associateQuery.loading} className="btn" onClick={addHandler}>Adicionar</button>
          </div>
        </div>
        <div className="grid grid--gap-sm grid--2">
          <AssociationList associations={associations} />
          <div className="form-actions">
            <button className="btn" onClick={fecharModalHandler}>Fechar</button>
          </div>
        </div>
      </div>
    </div>
  )
}



export default AccountEventModal